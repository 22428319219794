import { useEffect, useState } from 'react';
import { FilterOptions } from './types';
import {
  distanceFilterOptions,
  gradeFilterOptions,
  gradePreferencesOptions,
  connectSubjectOptions,
} from 'features/Connect/features/JobDiscovery/constants';
import marketplaceSchoolsAPI from 'features/Connect/api/marketplaceSchoolsAPI';
import { useStateParam } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants/connect';

interface FilterAndLoadingOptions extends FilterOptions {
  isLoadingFilterOptions: boolean;
}

export const useConnectFilterOptions = (): FilterAndLoadingOptions => {
  const jobboardState = useStateParam();
  const stateCode =
    CONNECT_JOBBOARD_STATES[jobboardState]?.stateCode || CONNECT_JOBBOARD_STATES.MISSOURI.stateCode;
  const [districtOptions, setDistrictOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [distanceOptions, setDistanceOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const path = window.location.pathname;
  const isUserPreferencesPage = path.includes('/preferences');

  useEffect(() => {
    const districtPromise = !isUserPreferencesPage
      ? marketplaceSchoolsAPI
          .getDistrictFilterOptions({ stateCode: stateCode })
          .then((districts) =>
            setDistrictOptions(
              districts.map((district) => ({ label: district.name, value: district.nces_id }))
            )
          )
      : Promise.resolve([]);

    const schoolPromise = marketplaceSchoolsAPI
      .getSchoolFilterOptions({ stateCode: stateCode })
      .then((schools) => {
        const formattedAndSortedSchools = schools
          .map((school) => ({
            label: school.name,
            value: school.nces_id,
            address: {
              ...school.physical_address,
              city: school.physical_address.city,
              state: school.physical_address.state,
            },
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setSchoolOptions(formattedAndSortedSchools);
      });

    setSubjectOptions(connectSubjectOptions);

    setDistanceOptions(distanceFilterOptions);
    setGradeOptions(isUserPreferencesPage ? gradePreferencesOptions : gradeFilterOptions);

    Promise.all([districtPromise, schoolPromise]).finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobboardState]);

  return {
    districtOptions,
    schoolOptions,
    subjectOptions,
    distanceOptions,
    gradeOptions,
    isLoadingFilterOptions: isLoading,
  };
};
