import { UseMutationResult, UseMutationOptions } from '@tanstack/react-query';

import { NimbleConnectProfile } from './connectTypes';
import { JobClosureNotification } from 'components/JobEdit/types';
import { CreatedBy, JobviewJobboard, RequiredApplicationAttachment } from 'features/JobView/types';

declare global {
  interface Window {
    Intercom(update: string, params: { hide_default_launcher: boolean }): void;
    pendo?: any;
  }
}

export type Json = boolean | number | string | null | { [key: string]: Json } | Array<Json>;

export interface Application {
  id: number;
  new_status_label: string;
  role__active_status: number;
  role__id: number;
  role__status: number;
  role__title: string;
}

export interface ApplicationStatus {
  id?: number;
  label?: string;
  type?: number;
  status_type?: number;
  color?: number;
  school_admin_move_access?: boolean;
  school_admin_move_district?: boolean;
  school_admin_view_access?: boolean;
  _order?: number;
}

export interface Answers {
  answer: string;
  question: string;
}

export interface Attachment {
  title: string;
  file: string;
  id: number | string;
  attachment: string;
}

export interface BasicLocationPreferencesProps {
  milesWithin: number;
  location: string;
  checked: boolean;
  showMapErrorInput: boolean;
  showMapErrorSelect: boolean;
  combinedPreferenceAndProfile: boolean;
  handleChangeSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface Candidate {
  applications: CandidateApplication[];
  modified?: unknown;
  email: string;
  first_name: string;
  id: number;
  name: string;
  note_count: number;
  profile: CandidateProfile;
  prospect_id?: string;
  scorecard_count: number;
  allRelevantApplicationsForSchoolAdmin?: CandidateApplication[];
  status?: number;
  district_source?: 'N/A' | 'none';
}

export type CandidateApplication = Pick<
  UserApplication,
  'id' | 'role' | 'new_status' | 'updated' | 'updated_by' | 'top_candidate' | 'high_retention'
>;

export interface CandidateDistrictInformation {
  user: User;
  marketplace_fit_status: MarketplaceFitStatus;
  categories_of_interest_count: number;
}

export interface CandidateProfile {
  id?: number;
  district_id?: number;
  has_no_credentials: boolean;
  phone_cell: string;
  phone_work: string;
  updated: Date;
  years_experience: number;
}

export interface CandidateProfile {
  district_id?: number;
  name: string;
  email: string;
  has_no_credentials: boolean;
  phone_cell: string;
  phone_work: string;
  updated: Date;
  years_experience: number;
}

export interface CandidateSource {
  id: number;
  label: string;
  value: string;
  district: District;
  immutable: boolean;
  deleted: boolean;
}

export interface Category {
  id: number;
  label: string;
  subcategories: Subcategory[];
  district_id: number;
}

export interface Credential {
  state: number | null;
  certificate: number | null;
  credential_exams_passed: Array<number>;
  credential_explanation: string;
  expiration_date: string | null;
  grade: CredentialDropdownField | null;
  id: number;
  issue_date: string | null;
  license_number: string | null;
  status: CredentialDropdownField | null;
  subject: CredentialDropdownField | null;
  nj_certificate?: number;
}

export interface Credential {
  id: number;
  value: number;
  label: string;
  key: number;
}

export interface CredentialDropdownField {
  id: number;
  label: string;
  value: string;
  key: number;
}

export interface CustomAnswers {
  answer: string;
  order: number;
  question: string;
  question_set_uuid: string;
}

export interface CustomProfileFieldsInterface {
  id: number;
  district: number;
  district_admin_access: number;
  district_user_access: number;
  field_type: number;
  individual_users_edit_and_view: number[];
  individual_users_view_only: number[];
  multichoice_options: [];
  order: number;
  school_admin_access: number;
  super_admin_access: number;
  title: string;
  visibility_fp: boolean;
  visibility_qv: boolean;
}

export interface CustomScoreCard {
  [key: string]: unknown;
}

interface DemoMCOption {
  optionText: string;
  knockoutStatus: ApplicationStatus;
}

export interface District {
  id: number;
  name: string;
  city: string | null;
  state: string | null;
  slug: string | null;
  ssn_required: boolean;
  birthday_required: boolean;
  indiana_district: boolean;
  has_onboarding_module: boolean;
  has_prospects_module: boolean;
  posting_only: boolean;
}

export interface DistrictReference {
  id: number;
  status: number;
}

export interface DistrictRole {
  id: number;
  requisition_number: string;
  subcategories: number[];
}

export interface DistrictSource {
  district: number;
  id: number;
  immutable: boolean;
  label: string;
  value: string;
}

export interface DropdownSelectOption {
  value: number;
  label: string;
}

export interface Education {
  school: number;
  major: string;
}

export interface Exam {
  type: number;
}

export interface FlatFileTransferInterface {
  id: string;
  name: string;
  district: Partial<District>;
  report_columns: ReportColumn[];
  application_statuses?: ApplicationStatus[];
  created?: string;
  modified?: string;
}

export interface FlatFileTransferPayloadPOST {
  name: string;
  district: Partial<District>;
  report_columns: string; // Note: backend expects JSON of "report_columns":["columns"]
  application_statuses?: ApplicationStatus[];
}

export interface FlatFileTransferResponseInterface {
  id: string;
  name: string;
  district: Partial<District>;
  report_columns: string;
  application_statuses?: ApplicationStatus[];
  created?: string;
  modified?: string;
}

export interface Grade {
  value: number;
  label: string;
  key: string;
}

export interface HRBPAssignment {
  id?: number;
  admin_users: number[];
  schools: number[];
  role: number | null;
}

export interface InternalRoleNote {
  id?: number;
  is_deleted?: boolean;
  is_edited?: boolean;
  created_by_id?: number;
  created_by?: User;
  created_at?: string;
  tagged_users: number[];
  newly_tagged_users?: number[];
  text: string;
}

type JobDistrictRole = {
  requisition_number: string;
};

export interface Job {
  benefits: string;
  contact: string;
  custom_questions: Question[];
  customScorecardsSelected?: CustomScoreCard[];
  deadline: string | null;
  description: string;
  district_users: number[];
  district?: number | null;
  external_date: string | null;
  ftes_total: number;
  fulltime: number;
  grades: number[];
  hiring_season: number;
  hrbp_assignments: HRBPAssignment[];
  id: number | null;
  internal_only: boolean;
  internal_requirements_specified: boolean;
  internal_role_notes: InternalRoleNote[];
  is_template: boolean;
  jobboards: Jobboard[] | JobviewJobboard[];
  link_to_ats: string | boolean;
  onet_occupation_code: string;
  owners: number[];
  question_sets_order: QuestionSetOrderObj[];
  question_sets: QuestionSet[];
  questions: Question[];
  requiredapplicationattachment_set: Question[] | RequiredApplicationAttachment[];
  requisition_number?: string;
  role_question_sets: QuestionSet[];
  role_sections: RoleSection[];
  salary_max?: number;
  salary_min?: number;
  salary_type?: number;
  school_preferences_question: Question | null;
  school?: School;
  schoolroles?: JobViewSchoolRole[];
  scorecards?: CustomScoreCard[];
  start_date_type: number;
  start_date: string | null;
  status: number;
  statuses_available: ApplicationStatus[];
  subjects: number[];
  title: string | null;
  district_role?: JobDistrictRole;
  notifications?: JobClosureNotification[];
}

export interface Jobboard {
  id: number;
}

export interface JobErrors {
  hrbp_assignments: string | boolean;
  title: string | boolean;
  job_description: string | boolean;
  role_type: string | boolean;
  link_to_ats: string | boolean;
  subjects: string | boolean;
  grades: string | boolean;
  non_field_errors: string | boolean;
  start_date: string | boolean;
  fulltime: string | boolean;
  deadline: string | boolean;
  salary_min: string | boolean;
  salary_max: string | boolean;
}

export type JobViewSchoolRole = Omit<
  Job,
  | 'district'
  | 'created_by'
  | 'question_ses'
  | 'external_date'
  | 'hrbp_assignments'
  | 'internal_only'
  | 'question_sets'
  | 'internal_requirements_specified'
  | 'is_template'
  | 'jobboards'
  | 'question_sets'
  | 'link_to_ats'
  | 'onet_occupation_code'
  | 'owners'
  | 'question_sets_order'
  | 'requiredapplicationattachment_set'
  | 'role_question_sets'
  | 'role_sections'
  | 'start_date_type'
> & {
  active_status: number;
  automatic_routing: boolean;
  categories?: string[];
  created_by: CreatedBy;
  created: string;
  date_posted: string;
  district: District;
  end_date?: string;
  has_unfilled_vacancies: boolean;
  languages?: string[];
  submitted_by?: string;
  updated_by: number;
  updated: string;
  visible_to_school_admin: boolean;
  district_role: DistrictRole;
  name?: string;
};

export interface Language {
  language: number;
}

export interface LocationPreferencesProps {
  milesWithin: number;
  location: string;
  checked: boolean;
  showMap: boolean;
  showMapErrorInput: boolean;
  showMapErrorSelect: boolean;
  mapUrl: string;
  combinedPreferenceAndProfile: boolean;
  handleChangeSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hadleSearchButton: (
    event: React.MouseEvent<HTMLButtonElement> | React.FocusEvent<HTMLInputElement>,
    location: string,
    milesWithin: number
  ) => void;
  handleAddressSearchOnEnter: (
    event: React.KeyboardEvent<HTMLInputElement>,
    location: string,
    milesWithin: number
  ) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface MarketplaceCandidate {
  categories_of_interest_count: number;
  id?: number;
  district: number;
  user: User;
  updated: string;
  updated_by: number;
  marketplace_fit_status: MarketplaceFitStatus | null;
}

export enum MarketplaceFitStatus {
  NO_FIT = 'no_fit',
  POTENTIAL_FIT = 'potential_fit',
  CONVERTED_TO_PROSPECT = 'converted_to_prospect',
}

export enum nimbleProduct {
  'connect' = 'connect',
  'ats' = 'ats',
}

export interface PathObj {
  innerText: string;
  alt: string | undefined;
  className: string | undefined;
}

export interface Preference {
  location: string;
  multilingual_school: number | null;
  title_1_school: number | null;
  turnaround_school: number | null;
  employment_type: number | null;
  hours_per_week: string;
  grades: number[] | null;
  categories: number[] | null;
  miles_within: string;
  open_to_opportunities: boolean | null;
  visible_outside_radius: boolean | null;
  states: number[] | null;
  districts_excluded: number[] | null;
  nimble_connect_profile?: NimbleConnectProfile;
}

export interface Profile {
  spn: string | null;
  resume: string | null;
  ssn: string | null;
  phone_cell: string;
  phone_work: string;
  twitter_handle: string;
  middle_name: string;
  address_street: string;
  address_apt: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
  states_lived_in: number[];
  website: string;
  gender: number | null;
  race_ethnictiy: number | null;
  birthday: string;
  has_no_credentials: boolean | null;
  has_no_certificates: boolean | null;
  has_ged: boolean | null;
  other_certificate_explanation: string;
  certificates_held: number[];
  years_experience: number;
  updated: string;
  district?: District;
  user_type?: number;
  connect_first_profile_setup?: boolean;
}

export interface Profile {
  birthday: string;
  district_id: number;
  has_no_credentials: boolean | null;
  permissions: {
    view_birthday: boolean;
    view_ssn: boolean;
  };
  phone_cell: string;
  phone_home: string;
  phone_work: string;
  resume: string | null;
  ssn: string | null;
  updated: string;
  years_experience: number;
}

export interface Question {
  autotags?: Tag[];
  demo_mc_options?: DemoMCOption[] | null;
  description: string | null;
  district: number | null;
  hint: string;
  id?: number | null;
  included_in_preview?: boolean;
  is_automatic_list: boolean;
  is_district_default: boolean;
  is_knockout_question?: boolean;
  is_legal: boolean;
  is_required_external: boolean;
  is_required_internal: boolean;
  is_visible_external: boolean;
  is_visible_internal: boolean;
  isShowingEditOptions: boolean;
  localId: string | null;
  mc_options: string[] | null;
  multi_select: boolean;
  nimblequestion_id: number | null;
  prompt: string | null;
  question_type: QuestionType | null;
  question: string | null;
  school_choices: number[];
  source: number | null;
  text: string | null;
  title: string | null;
  uuid?: string | null;
}

export interface QuestionSet {
  id: number | null;
  is_qs: boolean;
  is_role_qs: boolean;
  isShowingEditOptions?: boolean;
  items: Question[];
  localId: string | null;
  nimblequestion_id?: number; //this is here so questions/question sets can be compared
  order?: number;
  question_type: null;
  title: string;
  uuid: string | null;
  requiredapplicationattachment_set?: RequiredApplicationAttachment[];
}

export interface QuestionSetOrderObj {
  uuid: string;
  order: number;
  is_role_qs: boolean;
}

export enum QuestionType {
  direction_text = 'question_type/direction_text',
  open_response = 'question_type/open_response',
  yes_no = 'question_type/yes_no',
  multiple_choice = 'question_type/multiple_choice',
  nimble = 'question_type/nimble',
  attachment = 'question_type/attachment',
  videoLink = 'question_type/video_link',
  schoolPreferences = 'question_type/school_preferences',
  statementCheckbox = 'question_type/statement_checkbox',
  text_question_model = 'question_type/legacy_text_question',
}

export interface QuickProfileUser extends User {
  user?: User;
  answers?: Array<Answers>;
  profile?: Profile;
  district_phone?: string;
  applications?: Array<Application>;
  candidate_district_information?: CandidateDistrictInformation;
  district_source: DistrictSource | 'none' | null;
  district_references?: Array<DistrictReference>;
  next_interview?: {
    when: string;
    integration_label: string;
  };
  attachments: Array<Attachment>;
  application?: UserApplication;
  preference?: Preference;
  prospect_id?: number;
}

export interface Reference {
  first_name: string;
  last_name: string;
  title: string;
  relationship: string;
  phone: string;
  organization: string;
}

export interface ReportColumn {
  id: string;
  title: string;
  type: string;
  column_value?: string;
  mapping?: Record<string, string>;
  profile_field?: string;
  custom_profile_field_id?: number;
  hellosign_template_id?: number | string;
  hellosign_field_name?: string;
  input_columns?: ReportColumn[];
  separator?: string;
  formats?: string[];
  template_id?: string;
  application_status_id?: string;
  role_field?: string;
  application_field?: string;
}

export interface Role {
  hrbp_assignments: Array<HRBPAssignment>;
  id: number;
  question_sets_order: Array<QuestionSetOrderObj>;
  requiredapplicationattachment_set: Array<Record<string, unknown>>;
  statuses_available: number[];
  title: string;
  district_role?: DistrictRole;
}

export interface RoleSection {
  id?: number;
  required: boolean;
  required_internal?: boolean;
  role?: number;
  section: number;
  visible: boolean;
  visible_internal?: boolean;
}

/**
 * Handles most instances of role/job templates
 */
export interface RoleTemplate {
  id: number | null;
  district: number;
  jobboards: Jobboard[];
  benefits: string;
  contact: string;
  external_date: string | null;
  deadline: string | null;
  onet_occupation_code: string;
  question_sets_order: QuestionSetOrderObj[];
  internal_role_notes: InternalRoleNote[];
  internal_only: boolean;
  hiring_season: number;
  questions: Question[];
  custom_questions: Question[];
  requiredapplicationattachment_set: Question[];
  question_sets: QuestionSet[];
  role_question_sets: QuestionSet[];
  title: string | null;
  ftes_total: number;
  requisition_number: string;
  owners: number[];
  district_users: number[];
  description: string | null;
  link_to_ats: string | boolean;
  internal_requirements_specified: boolean;
  status: number;
  statuses_available: ApplicationStatus[];
  hrbp_assignments: HRBPAssignment[];
  start_date_type: number;
  role_sections: RoleSection[];
  grades: number[];
  salary_min: number;
  salary_max: number;
  salary_type: number;
  subjects: number[];
  school_preferences_question: Question | null;
  start_date: string | null;
  fulltime: number;
  customScorecardsSelected?: CustomScoreCard[];
  scorecards?: CustomScoreCard[];
  is_template: boolean;
  schoolroles?: SchoolRole[];
  created_by?: {
    name: string;
    id: number;
    email: string;
    username: string;
  };
  created: string;
}

export interface School {
  id: number;
  name: string;
  location: string;
  ftes_total?: number;
  visible_to_school_admin?: boolean;
  has_unfilled_vacancies?: boolean;
  isGroupHeader?: boolean;
  school_groups?: number[];
}

export interface SchoolPreferenceAnswer {
  selected_schools: any[];
}

export interface SchoolRole extends Job {
  automatic_routing: boolean;
  visible_to_school_admin: boolean;
  has_unfilled_vacancies?: boolean;
}

export interface SearchTalentData extends User {
  marketplace_fit_status: MarketplaceFitStatus;
  categories_of_interest_count: number;
}

export interface Subcategory {
  id: number;
  label: string;
  state_id: number;
}

export interface Tag {
  id?: number;
  tag_id: number;
  option: 'Yes' | 'No' | 'Disagree' | 'Agree';
  label?: string;
}

export interface UniversalTask {
  id: number;
  title: string;
}

export interface User {
  id: number | null;
  name: string;
  email: string;
  display?: string;
  first_name: string;
  last_name: string;
  credentials?: Credential[];
  experiences?: WorkExperience[];
  additional_references?: Reference[];
  languages?: Language[];
  exams?: Exam[];
  education?: Education[];
  profile?: Partial<Profile>;
  agree_tos?: boolean;
  username?: string;
  linked_users?: string[];
  preference?: Preference;
}

export interface UserApplication {
  top_candidate: boolean;
  applicationattachment_set: Array<Record<string, unknown>>;
  applicationattachmentvideourl_set: Array<Record<string, unknown>>;
  custom_answers: Array<CustomAnswers>;
  role: Role;
  flexible_school_preferences: boolean;
  high_retention: boolean;
  id: number;
  is_in_shortage_area: boolean;
  is_popular: boolean;
  preferenced_school: boolean;
  updated: Date;
  updated_by: string;
  new_status: {
    color: number;
    id: number;
    label: string;
    status_type: number;
  };
  school_preferences_answer: SchoolPreferenceAnswer;
  schoolapplications: unknown[];
}

export interface UserWithProfile extends User {
  profile?: Profile;
}

export interface VisibleTemplate {
  id: number;
  title: string;
  template_id: string;
}

export interface WorkExperience {
  title: string;
  start_date: string;
  end_date: string;
  organization: string;
  name: string;
  experience_types: number[];
  grades: number[];
}

// types shares same generics as react-query itself
export type UseMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = (
  options: UseMutationOptions<TData, TError, TVariables, TContext>
) => UseMutationResult<TData, TError, TVariables, TContext>;

export interface SignupSourcesCookie {
  referrer?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}
