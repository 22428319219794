import {
  AttachmentItem,
  AttachmentsContainer,
  AttachmentTitle,
  JobViewSectionTitle,
  RightAttachmentsSection,
} from './styles';
import { Box, useTheme } from '@mui/material';
import auth from 'utils/auth';
import FileIcon from '../assets/file-icon.svg';
import { userTypeToId } from 'utils/enums';
import { JobViewAttachmentsProps } from '../types';
import { useRouteMatch } from 'react-router-dom';
import { Accordion } from 'sharedComponents/Accordion/Accordion';

export const JobViewAttachments: React.FC<JobViewAttachmentsProps> = ({
  attachments,
  isInternalCandidate,
}) => {
  const isDistrictRoute = useRouteMatch('/district')?.path === '/district';
  const isSchoolRoute = useRouteMatch('/school')?.path === '/school';
  const nimbleTheme = useTheme();

  const onInternalPage =
    (auth.getUser()?.profile?.user_type !== userTypeToId.candidate && isDistrictRoute) ||
    isSchoolRoute;

  const isRequired = (question) => {
    if (onInternalPage) {
      return question.is_required_external || question.is_required_internal;
    } else {
      if (isInternalCandidate) {
        return question.is_required_internal;
      } else {
        return question.is_required_external;
      }
    }
  };

  const isLastAndOddNumberAttachments = (index) => {
    const attachmentsCount = attachments.length;
    const hasOddNumberOfAttachments = attachmentsCount % 2 !== 0;

    if (index === attachmentsCount - 1 && hasOddNumberOfAttachments) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Accordion
      sx={{ minHeight: 0, padding: 0 }}
      defaultExpanded
      title={<JobViewSectionTitle variant="h2">Attachments</JobViewSectionTitle>}
      dataTestId="attachments-section"
    >
      <AttachmentsContainer data-testid="attachments">
        {attachments.map((attachment, index) => (
          <AttachmentItem
            key={attachment.id}
            data-testid="attachment"
            isLastAndOddNumber={isLastAndOddNumberAttachments(index)}
          >
            <img
              src={FileIcon}
              style={{ width: nimbleTheme.spacing(5), height: nimbleTheme.spacing(5) }}
            />
            <RightAttachmentsSection>
              <AttachmentTitle variant="body1" data-testid="attachment-title">
                {attachment.title}
              </AttachmentTitle>
              <Box data-testid="is-required">
                {isRequired(attachment) ? 'Required' : 'Optional'}
              </Box>
            </RightAttachmentsSection>
          </AttachmentItem>
        ))}
      </AttachmentsContainer>
    </Accordion>
  );
};
