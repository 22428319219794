import { Accordion } from 'sharedComponents/Accordion/Accordion';
import { Author, AuthorDate, Date, InternalNotesContainer, Message, Row } from '../styles';
import { JobViewInternalNotesProps, JobViewInternalNotesRowProps } from '../types';
import { makeAllUrlsValid } from 'utils/util';
import moment from 'moment';
import { JobViewSectionTitle } from './styles';
import { Box } from '@mui/material';

export const JobViewInternalNotesRow: React.FC<JobViewInternalNotesRowProps> = ({
  internalNote,
}) => {
  const author = internalNote.created_by?.name || 'Unspecified user';
  const date = moment(internalNote.created_at).format('MMM DD, h:mm a');
  const message = internalNote.text;

  return (
    <Row data-testid="internal-note">
      <AuthorDate data-testid="internal-note-signature">
        <Author data-testid="internal-note-author">{author}</Author>{' '}
        <Date variant="body3" data-testid="internal-note-date">
          {date}
        </Date>
      </AuthorDate>
      <Message
        data-testid="internal-note-message"
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: makeAllUrlsValid(message),
        }}
      />
    </Row>
  );
};

export const JobViewInternalNotes: React.FC<JobViewInternalNotesProps> = ({ internalNotes }) => {
  return (
    <Box component="section">
      <Accordion
        sx={{ minHeight: 0, padding: 0 }}
        defaultExpanded
        title={<JobViewSectionTitle variant="h2">Internal Notes</JobViewSectionTitle>}
        dataTestId="jobview-internal-notes"
      >
        <InternalNotesContainer className="ql-editor" data-testid="internal-notes">
          {internalNotes.map((internalNote) => (
            <JobViewInternalNotesRow key={internalNote.id} internalNote={internalNote} />
          ))}
        </InternalNotesContainer>
      </Accordion>
    </Box>
  );
};
