import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, Theme, useTheme } from '@mui/material';
import { BasicSelect } from 'sharedComponents/Select';
import { Input } from 'sharedComponents/Input';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { useEffect, useState } from 'react';
import GeolocationAPI from '../../features/Connect/api/geolocationAPI';
import { LocationFilterProps } from './types';
import { ActiveFilters } from 'features/StateJobBoard/types';
import SearchIcon from '@mui/icons-material/Search';

export const LocationFilter: React.FC<LocationFilterProps> = ({
  filterOptions,
  distanceDisplayName,
  locationDisplayName,
  locationChangeHandler,
  actions,
  setShowMap,
  setMapUrl,
  mapUrl,
  dataTestIds,
  searchTerm,
  isExternalSubmit,
  setIsExternalSubmit,
  hasKeywordSearch = false,
  closeDrawer,
  selectedDistance,
  previousSelectedDistance,
  setSelectedDistance,
  locationInput,
  previousLocationInput,
  setLocationInput,
  isLoading,
  setIsLoading,
}) => {
  const [localSelectedDistance, setLocalSelectedDistance] = useState<string>(
    selectedDistance || ''
  );
  const [localLocationInput, setLocalLocationInput] = useState<string>(locationInput || '');
  const [locationLongitude, setLocationLongitude] = useState<string>('');
  const [locationLatitude, setLocationLatitude] = useState<string>('');
  const [showMapInputError, setShowMapInputError] = useState<boolean>(false);
  const [showMapSelectError, setShowMapSelectError] = useState<boolean>(false);
  const [localMapUrl, setLocalMapUrl] = useState<string>('');

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));
  const theme = useTheme();

  const validateLocationAndDistance = () => {
    const isInputError = !localLocationInput && !!localSelectedDistance;
    const isSelectError = !!localLocationInput && !localSelectedDistance;

    setShowMapInputError(isInputError);
    setShowMapSelectError(isSelectError);

    return !isInputError && !isSelectError;
  };

  const keywordLocationSearchHandler = (event = null) => {
    if (event) {
      event.preventDefault();
    }

    const isValid = validateLocationAndDistance();
    if (!isValid) {
      setIsLoading(false);
      setIsExternalSubmit(false);
      return;
    }

    if (hasKeywordSearch && searchTerm) {
      actions.setQuery(searchTerm);
    }

    if (localLocationInput && localSelectedDistance) {
      setIsLoading(true);
      setIsExternalSubmit(false);
      handleAddressSearch(localLocationInput, localSelectedDistance, event);
    }
  };

  const handleAddressSearch = async (
    location: string,
    distance: string,
    event?: React.FormEvent
  ) => {
    if (event) {
      event.preventDefault();
    }

    if (!location || !distance) return;

    const data = { address: location, radius: distance, isMobile };
    const response = await GeolocationAPI.getMapUrl(data);

    if (response[0] === 200) {
      const mapData = response[1];

      setShowMap(mapData.map_url ? true : false);
      setLocationLatitude(mapData.latitude);
      setLocationLongitude(mapData.longitude);
      setLocalMapUrl(mapData.map_url);
      setMapUrl(mapData.map_url);
    } else {
      const errorType = response[1].error;
      setShowMapInputError(errorType === 'invalid address' || errorType === 'address is required');
      setShowMapSelectError(!data.radius);
      setShowMap(false);
      setIsLoading(false);
      setLocalMapUrl('');
      setMapUrl('');
    }
  };

  useEffect(() => {
    if (mapUrl && localMapUrl !== '') {
      setShowMap(true);

      const distanceRadiusMiles = selectedDistance !== 'Any' ? selectedDistance : '';

      locationChangeHandler({
        ['locationLatitude']: locationLatitude,
        ['locationLongitude']: locationLongitude,
        ['distanceRadiusMiles']: distanceRadiusMiles,
      });

      actions.setActiveFilters((previousState: ActiveFilters) => ({
        ...previousState,
        distanceRadiusMiles: true,
        locationLatitude: true,
        locationLongitude: true,
      }));

      if (isMobile) {
        closeDrawer();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapUrl]);

  useEffect(() => {
    if (
      isExternalSubmit &&
      (localLocationInput || localSelectedDistance) &&
      (localLocationInput !== previousLocationInput ||
        localSelectedDistance !== previousSelectedDistance)
    ) {
      setSelectedDistance(localSelectedDistance);
      setLocationInput(localLocationInput);
      keywordLocationSearchHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExternalSubmit]);

  return (
    <Grid container spacing={theme.spacing(1)} justifyContent={'space-between'} rowGap={0.5}>
      <Grid item xs={8} md={6} lg={7}>
        <Input
          label="Location"
          dataTestId={dataTestIds.locationInput}
          placeholder={locationDisplayName}
          onChange={(e) => {
            setLocalLocationInput(e.target.value);
            setLocationInput(e.target.value);
          }}
          value={localLocationInput}
          size={isMobile ? 'medium' : 'small'}
          hasError={showMapInputError}
          sx={InputStyles(theme, showMapInputError)}
        />
        {showMapInputError && (
          <StyledTypography>Please enter a valid address or zipcode</StyledTypography>
        )}
      </Grid>
      <Grid container item direction={'row'} spacing={1} xs={4} md={6} lg={5}>
        <Grid item xs={12} md={8} lg={7}>
          <BasicSelect
            displayName={distanceDisplayName}
            dataTestId={dataTestIds.distanceFilter}
            handleChange={(distance) => {
              setLocalSelectedDistance(distance);
              setSelectedDistance(distance);
            }}
            options={filterOptions}
            sx={selectStyles(theme)}
            size={isMobile ? 'medium' : 'small'}
            defaultValue={localSelectedDistance || ''}
            values={localSelectedDistance}
            showError={showMapSelectError}
          />
          {showMapSelectError && <StyledTypography>Please select an option</StyledTypography>}
        </Grid>
        {!isMobile && (
          <Grid item xs={3} md={4} lg={5}>
            <PrimaryButton
              dataTestId={dataTestIds.locationDistanceKeywordSubmitButton}
              size="large"
              onClick={(event) => {
                setSelectedDistance(localSelectedDistance);
                setLocationInput(localLocationInput);
                keywordLocationSearchHandler(event);
              }}
              sx={buttonStyles}
              isLoading={isLoading}
              loadingSpinnerSize={20}
              loadingSpinnerColor="inherit"
            >
              {isTablet ? <SearchIcon /> : <Typography alignSelf={'center'}>Search</Typography>}
            </PrimaryButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const InputStyles = (theme: Theme, hasError: boolean) => ({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: theme.spacing(4.75),
    marginBottom: theme.spacing(2),
  },

  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
      height: theme.spacing(2.5),
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
    ...(hasError && {
      borderColor: theme.palette.error.main,
    }),
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const selectStyles = (theme: Theme) => ({
  width: '100%',
  minWidth: theme.spacing(13.75),
  borderRadius: theme.shape.borderRadius * 0.5,

  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputLabel-shrink': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
});

const buttonStyles = (theme: Theme) => ({
  minWidth: '100%',
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius * 0.5,
  backgroundColor: theme.palette.black.main,
  '&:hover': {
    backgroundColor: theme.palette.gray.medium,
  },
});
