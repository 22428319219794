import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import lazyRegistrationAPI from 'api/lazyRegistrationAPI';
import { ConnectMarketingInputAndButton } from 'features/Connect/components/ConnectMarketingInputAndButton/ConnectMarketingInputAndButton';
import { ValuePropBannerGraphic } from 'features/Connect/components/ValuePropBanner/ValuePropBannerGraphic';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Chip } from 'sharedComponents/Chip';
import { nimbleProduct } from 'types/types';
import auth from 'utils/auth';
import { isValidEmail } from 'utils/emailutils';
import { MagicLinkModal } from '../ConnectSignUpLogin/MagicLinkModal';
import { JobsDiscoveryValuePropBannerProps } from './types';
import { ConnectMarketingMessageAndButton } from 'features/Connect/components/ConnectMarketingInputAndButton/ConnectMarketingMessageAndButton';
import { getStateLabel } from './utils';
import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { connectRedirectUrls, connectUrlParams } from './constants';
import { useStateParam } from '../ConnectStateCodeContextProvider';
import { buildUrl } from 'utils/util';

export const JobsDiscoveryValuePropBanner: React.FC<JobsDiscoveryValuePropBannerProps> = ({
  isUserAuthenticated,
}) => {
  const stateCode = useStateParam();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [magicLinkModalOpen, setMagicLinkModalOpen] = useState(false);
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    if (email !== '') {
      const emailValid = isValidEmail(email);
      setSubmitButtonActive(emailValid);
    } else {
      setSubmitButtonActive(true);
    }
  }, [email]);

  const handleSubmit = async () => {
    if (email === '') return;

    setIsLoading(true);
    const userData = await lazyRegistrationAPI.registerUser(
      email,
      true,
      nimbleProduct.connect,
      buildUrl(connectRedirectUrls.PROFILE, { [connectUrlParams.REDIRECT]: stateCode }),
      ConnectGatedActions.SIGNUP_BANNER
    );
    setIsLoading(false);

    if (userData?.login_required) {
      setMagicLinkModalOpen(true);
    } else {
      auth.setUser(userData);
      history.push(
        buildUrl(connectRedirectUrls.PROFILE, { [connectUrlParams.REDIRECT]: stateCode })
      );
    }
  };

  const chipStyles = {
    color: '#3B5BDB',
    border: '1px solid #EDF2FF',
    background: '#dbe4ff4d',
    display: 'inline-block',
    width: 'fit-content',
  };

  return (
    <ValuePropBannerContainer>
      {magicLinkModalOpen && (
        <MagicLinkModal
          email={email}
          isOpen={magicLinkModalOpen}
          closeModal={() => setMagicLinkModalOpen(false)}
          expiredModal={false}
          currentPath={'/connect'}
        />
      )}
      <HeaderAndInputContainer>
        <HeaderAndChipContainer>
          <Chip
            label={`See all ${getStateLabel(stateCode)} teaching jobs in one place \xa0\u2192`}
            variant="filled"
            sx={chipStyles}
          />
          <HeaderTextContainer>
            <HeaderText>Find a teaching job</HeaderText>
            <HeaderText>you&apos;ll love.</HeaderText>
          </HeaderTextContainer>
        </HeaderAndChipContainer>
        {isUserAuthenticated ? (
          <AuthenticatedUserContainer>
            <ConnectMarketingMessageAndButton isLoading={isLoading} />
          </AuthenticatedUserContainer>
        ) : (
          <InputAndTOSContainer>
            <ConnectMarketingInputAndButton
              onSubmit={handleSubmit}
              email={email}
              setEmail={setEmail}
              isLoading={isLoading}
              isActive={submitButtonActive}
            />
            <TOSTypography>
              {'I have read, understand, and agree to be bound by the Nimble '}
              <Link
                href="https://www.hirenimble.com/terms"
                rel="noopener noreferrer"
                target="_blank"
                color={theme.palette.secondary.main}
                sx={{
                  fontWeight: theme.typography.fontWeightMedium,
                  '&:hover': {
                    color: theme.palette.secondary.dark,
                  },
                }}
              >
                {'terms of use'}
              </Link>
              .
            </TOSTypography>
          </InputAndTOSContainer>
        )}
      </HeaderAndInputContainer>
      <ValuePropBannerGraphic />
    </ValuePropBannerContainer>
  );
};

const ValuePropBannerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  justifyContent: 'space-between',
  marginTop: theme.spacing(6),

  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: theme.spacing(7),
  },
}));

const HeaderAndInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const HeaderAndChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(1.5),
  },
}));

const HeaderTextContainer = styled(Box)(({ theme }) => ({
  maxHeight: '576px',
  gap: theme.spacing(1),

  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(2),
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontWeight: 600,
  lineHeight: theme.spacing(9),

  [theme.breakpoints.down('sm')]: {
    fontSize: '29.8px',
    lineHeight: theme.spacing(4),
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    fontSize: '39px',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '56px',
  },
}));

const AuthenticatedUserContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const InputAndTOSContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const TOSTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  color: theme.palette.text.light,
  fontSize: '13px',
  fontWeight: theme.typography.fontWeightLight,
}));
