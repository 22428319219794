import { ATSAttachmentsDataTestIds } from 'data-testids/ATS';
import styled from 'styled-components';

function GreenToggle({ toggleIsOn, toggleFunction, label, subtext, dataTestId }) {
  return (
    <>
      <OuterContainer data-testid={dataTestId}>
        <SwitchContainer on={toggleIsOn ? 'true' : undefined} onClick={toggleFunction}>
          <SwitchCircle on={toggleIsOn ? 'true' : undefined} />
        </SwitchContainer>
        {subtext ? (
          <TextContainer>
            <p className="bold">{label}</p>
            <p>{subtext}</p>
          </TextContainer>
        ) : (
          <p>{label}</p>
        )}
      </OuterContainer>
    </>
  );
}

export default GreenToggle;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;

  & > p {
    color: #444444;
  }
`;

const SwitchContainer = styled.div`
  width: 50px;
  min-width: 50px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid;
  border-color: ${(props) => (props.on ? '#00B88D' : '#dbdbdb')};
  margin-right: 10px;
  position: relative;
  cursor: pointer;
`;

const SwitchCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: ${(props) => (props.on ? '#00B88D' : '#dbdbdb')};
  left: ${(props) => (props.on ? '23px' : '3px')};
  top: 3px;
  transition: left 300ms;
`;

const TextContainer = styled.div`
  font-size: 14px;
  @supports (display: grid) {
    grid-template-columns: 1fr;
  }
`;
