import React from 'react';
import Hidden from '@mui/material/Hidden';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import ArchitectureOutlined from '@mui/icons-material/ArchitectureOutlined';
import AccountBalanceWalletOutlined from '@mui/icons-material/AccountBalanceWalletOutlined';
import { HighlightRowProps } from '../types';
import { Theme, styled } from '@mui/material';
import { formatDistance } from 'features/Connect/features/JobDiscovery/utils';
import { getGradeRange } from 'features/Connect/features/JobDiscovery/utils';

import { simpleBrowserUrlFormatter } from 'utils/util';

export const SchoolInfoHighlightRow: React.FC<HighlightRowProps> = React.memo(
  ({ school, isInterestModal, isMoreInfoSlider }) => {
    const { grades, district, distance_from_search_location_miles } = school;

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const formatSalaryLink = (link: string) => {
      try {
        return simpleBrowserUrlFormatter(link);
      } catch (e) {
        console.error(`Error formatting salary link ${link}`, e);
        return link;
      }
    };

    return (
      <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
        {distance_from_search_location_miles !== null &&
          distance_from_search_location_miles !== undefined && (
            <Stack direction="row" spacing={0.5}>
              <LocationOnOutlined sx={{ color: '#98A2B3' }} />
              <Hidden smDown>
                <Typography variant="body1" noWrap={true}>
                  {`${formatDistance(distance_from_search_location_miles)} miles away`}
                </Typography>
              </Hidden>
              <Hidden smUp>
                <Typography variant="body1" noWrap={true}>
                  {`${formatDistance(distance_from_search_location_miles)} miles`}
                </Typography>
              </Hidden>
            </Stack>
          )}
        {grades?.length > 0 && (
          <Stack direction="row" spacing={0.5}>
            <ArchitectureOutlined sx={{ color: '#98A2B3' }} />
            <Hidden smDown>
              <Typography variant="body1" noWrap={true}>
                {school?.grades.length > 1 ? 'Grades' : 'Grade'} {getGradeRange(grades)}
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Typography variant="body1" noWrap={true}>
                {getGradeRange(school?.grades)}
              </Typography>
            </Hidden>
          </Stack>
        )}
        {district?.salary_schedule_link && (
          <Stack direction="row" spacing={0.5}>
            <AccountBalanceWalletOutlined sx={{ color: '#98A2B3' }} />
            <a
              href={formatSalaryLink(district.salary_schedule_link)}
              rel="noreferrer"
              target="_blank"
            >
              {isMobile || isInterestModal ? (
                <SalaryScheduleLink variant="body1">Salaries</SalaryScheduleLink>
              ) : isMoreInfoSlider ? (
                <SalaryScheduleLink variant="body1">District Salaries</SalaryScheduleLink>
              ) : (
                <SalaryScheduleLink variant="body1">District Salary Schedule</SalaryScheduleLink>
              )}
            </a>
          </Stack>
        )}
      </Stack>
    );
  }
);

SchoolInfoHighlightRow.displayName = 'SchoolInfoHighlightRow';

const SalaryScheduleLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.black.dark,
  borderBottom: `solid 1px ${theme.palette.black.dark}`,

  '&:hover': {
    color: theme.palette.info.main,
    borderBottom: `solid 1px ${theme.palette.info.main}`,
  },

  '&:visited': {
    color: theme.palette.text.secondary,
    borderBottom: `solid 1px ${theme.palette.text.secondary}`,
  },
}));
