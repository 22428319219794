import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import auth from 'utils/auth';
import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import {
  CTAButtonContainer,
  ModalContainer,
  HelpText,
  Key,
  Row,
  SubContainer,
  Title,
  ErrorText,
  Form,
} from '../styles';
import { JobComboBox } from './JobComboBox';
import { LandingFormProps } from '../types';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { useQueryCheckIfCandidateHasApplication, useQuerySearchActiveOpenRoles } from '../queries';

export const LandingForm: React.FC<LandingFormProps> = ({
  candidateEmail,
  candidateId,
  candidateName,
  onSubmit,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState(inputValue);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const roleId = watch('roleId');

  const { data } = useQueryCheckIfCandidateHasApplication(candidateId, roleId, Boolean(roleId));

  const candidatesListURL = auth.isSchoolAdmin() ? '/school/candidates' : '/district/candidates';
  const hasFormErrors = Boolean(errors.role);

  const { data: options, isLoading: isDataLoading } = useQuerySearchActiveOpenRoles(
    debouncedInputValue,
    {
      enabled: debouncedInputValue.length > 0,
      keepPreviousData: true,
      staleTime: 60000,
      cacheTime: 120000,
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue]);

  const handleInputChange = (text: string) => {
    setInputValue(text);
  };

  const isLoading = isDataLoading && Boolean(inputValue);

  const title = options?.find((option) => option.id === roleId)?.title;
  const schools = options?.find((option) => option.id === roleId)?.schoolroles;

  const handleSubmit = (e) => {
    if (!title) return;

    onSubmit(e, title, schools);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ModalContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title variant="h1" data-testid={ATSConnectImportCandidateFormTestIds.TITLE}>
            Hire {candidateName}
          </Title>
        </Box>
        <SubContainer>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.CANDIDATE_NAME}>
            <Key>Candidate:</Key> &nbsp;{candidateName}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.CANDIDATE_EMAIL}>
            <Key>Candidate email:</Key> &nbsp; {candidateEmail}
          </Row>
        </SubContainer>
        <SubContainer>
          <Key>What role do you want to hire this candidate for?</Key>
          <Controller
            name="roleId"
            control={control}
            rules={{ required: 'Please select a role' }}
            render={({ field: { onChange } }) => (
              <JobComboBox
                data-testid={ATSConnectImportCandidateFormTestIds.JOB_COMBOBOX}
                onChange={onChange}
                hasError={Boolean(errors.role)}
                onInputChange={handleInputChange}
                isLoading={isLoading}
                options={options}
                inputValue={inputValue}
              />
            )}
          />
          {errors.role && <ErrorText>{errors.role.message}</ErrorText>}
          <HelpText>
            Don&apos;t see the role you want? Contact your administrator for more information or
            submit a job request.
          </HelpText>
        </SubContainer>
        {data?.has_existing_app === false && (
          <>
            <SubContainer
              data-testid={
                ATSConnectImportCandidateFormTestIds.CANDIDATE_HAS_NOT_APPLIED_INTERNAL_NOTE_SECTION
              }
            >
              <Key>Tell us a little bit about your interaction with the candidate so far:</Key>
              <Controller
                name="internalNote"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={6}
                    placeholder={`E.g. I have spoken on the phone twice with ${candidateName}. I was impressed by their qualifications, especially their experience in Title I schools.`}
                  />
                )}
              />
            </SubContainer>
            <CTAButtonContainer>
              <PrimaryButton
                type="submit"
                disabled={hasFormErrors}
                dataTestId={ATSConnectImportCandidateFormTestIds.IMPORT_CANDIDATE_BUTTON}
              >
                Import Candidate
              </PrimaryButton>
            </CTAButtonContainer>
          </>
        )}
        {data?.has_existing_app === true && (
          <>
            <SubContainer
              data-testid={
                ATSConnectImportCandidateFormTestIds.CANDIDATE_HAS_ALREADY_APPLIED_SECTION
              }
            >
              <Key>
                It looks like this candidate already has an active application for this role.
                Here&apos;s what you can do next:
              </Key>
              <li>
                Look through your candidate list for their application by clicking the button below.
              </li>
              <li>
                If you can&apos;t find this candidate&apos;s application, contact your administrator
                for more information on where the candidate is in the hiring process.
              </li>
            </SubContainer>
            <CTAButtonContainer>
              <PrimaryButton
                dataTestId={ATSConnectImportCandidateFormTestIds.VIEW_CANDIDATES_BUTTON}
                onClick={() => history.push(candidatesListURL)}
              >
                View Candidates
              </PrimaryButton>
            </CTAButtonContainer>
          </>
        )}
      </ModalContainer>
    </Form>
  );
};
