import { SaveSchoolResponse } from 'types/connectTypes.js';
import { BaseNimbleAPI } from '../../../api/baseAPI.js';

class UserSavedSchoolAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/connect_saved_school' };
  }

  async getSchoolsUserHasSaved(): Promise<SaveSchoolResponse[]> {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }

  async saveSchool(schoolNcesId: string) {
    const url = '/';
    const payload = {
      school_nces_id: schoolNcesId,
    };

    const response = await this.post(url, payload);
    return response;
  }

  async unsaveSchool(ncesSchoolId: string) {
    const url = `/${ncesSchoolId}/`;
    const response = await this.delete(url);
    return response;
  }
}

export default new UserSavedSchoolAPI();
