import { useState } from 'react';
import styled from 'styled-components';

import SignerRow from './SignerRow';
import { ConfirmationDialog } from 'ui-kit';

import { ReactComponent as PlusIconWithCircle } from 'assets/icon-plus-with-circle-grey.svg';
import { ReactComponent as MinusIconWithCircle } from 'assets/icon-minus-with-circle-grey.svg';
import { ItemContainer, LightText } from '.';
import { MUIAttachmentIcon } from 'components/Profile/styles';
import { DefaultContainer } from 'features/PrintView/styles';
import { ATSAttachmentsDataTestIds } from 'data-testids/ATS';

export default function PendingHellosignFormRow({ pendingForm, handleCancellation }) {
  const [expanded, setExpanded] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prevValue) => !prevValue);
  };

  const openConfirmationDialog = (e) => {
    e.stopPropagation();
    setConfirmationDialogOpen(true);
  };

  const resultHandler = (proceed) => {
    if (proceed) {
      handleCancellation(pendingForm.signature_request_id);
    }

    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <ItemContainer data-testid={ATSAttachmentsDataTestIds.PENDING_FORM}>
        <>
          <ClickableGridContainer onClick={toggleExpanded}>
            <MUIAttachmentIcon
              sx={{ paddingRight: 0 }}
              data-testid={ATSAttachmentsDataTestIds.PENDING_FORM_ATTACHMENT_ICON}
            />
            <LightText
              data-testid={ATSAttachmentsDataTestIds.PENDING_FORM_TEXT}
            >{`${pendingForm.title} (Awaiting Signature)`}</LightText>

            <DefaultContainer>
              <CancelButton
                data-testid={ATSAttachmentsDataTestIds.PENDING_FORM_CANCEL_BUTTON}
                onClick={openConfirmationDialog}
              >
                cancel form
              </CancelButton>
            </DefaultContainer>
            <DefaultContainer>
              {expanded ? (
                <CloseIcon />
              ) : (
                <OpenIcon data-testid={ATSAttachmentsDataTestIds.PENDING_FORM_EXPAND_BUTTON} />
              )}
            </DefaultContainer>
          </ClickableGridContainer>
          {expanded && (
            <>
              {pendingForm.signatures.map((signer) => (
                <SignerRow key={signer.signature_id} signer={signer} />
              ))}
            </>
          )}
        </>
      </ItemContainer>
      <ConfirmationDialog
        resultHandler={resultHandler}
        title="Delete form request"
        text="Are you sure you want to cancel this form request? The form and related tasks will be deleted."
        backLabel="Cancel"
        confirmLabel="Delete Form"
        isOpen={confirmationDialogOpen}
      />
    </>
  );
}

const ClickableGridContainer = styled.div`
  min-height: 42px;
  width: 100%;

  display: grid;
  grid-template-columns: 18px 1fr 85px 25px;
  grid-gap: 8px;
  align-items: center;

  cursor: pointer;

  @media print {
    display: flex;
    grid-gap: 0;
    min-height: 0;
  }
`;

const OpenIcon = styled(PlusIconWithCircle)`
  justify-self: end;
  cursor: pointer;
`;

const CloseIcon = styled(MinusIconWithCircle)`
  justify-self: end;
  cursor: pointer;

  margin-bottom: 3px;
`;

const CancelButton = styled.button`
  text-decoration: underline;
  border: none;
  background-color: #fff;
  font-size: 13px;

  &:hover {
    color: #666;
    text-decoration: none;
  }
`;
