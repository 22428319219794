import { styled } from '@mui/material';
import { Box, Typography } from '@mui/material';

export const getNestedValue = (obj, key) => {
  return key.split('.').reduce((acc, part) => (acc && acc[part] ? acc[part] : null), obj);
};

const renderSubLabels = (option, subLabelKeys, dataTestId) =>
  subLabelKeys.map((key, index) => {
    const nestedValue = getNestedValue(option, key);
    return (
      nestedValue && (
        <Typography
          key={`${key}-${index}`}
          variant="body2"
          data-testid={`${dataTestId}-${key}-${index}`}
          sx={{
            color: 'text.secondary',
            whiteSpace: 'nowrap',
          }}
        >
          {nestedValue}
        </Typography>
      )
    );
  });

export const renderOptionContent = (option, hasSubLabel, subLabelKeys, dataTestId?) => (
  <StyledOptionContent>
    <Typography variant="body1" sx={{ fontWeight: hasSubLabel ? 'bold' : 'auto' }}>
      {option.label}
    </Typography>
    {hasSubLabel && renderSubLabels(option, subLabelKeys, dataTestId)}
  </StyledOptionContent>
);

export const renderGroupHeaderContent = (
  params: any,
  renderGroupHeader?: (params: any) => React.ReactNode
) =>
  renderGroupHeader ? (
    renderGroupHeader(params)
  ) : (
    <StyledGroupHeader>
      <Typography variant="h6" sx={{ color: 'text.secondary', mb: 1 }}>
        {params.group}
      </Typography>
      {params.children}
    </StyledGroupHeader>
  );

const StyledOptionContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const StyledGroupHeader = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
