import Check from '@mui/icons-material/Check';
import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const GREEN = '#B6DDD2';

const StyledStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: GREEN,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: GREEN,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: GREEN,
    borderWidth: 1,
  },
}));

const StyledStepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#727272',
    borderRadius: '50%',

    ...((ownerState.active || ownerState.completed) && {
      color: '#fff',
      backgroundColor: '#45C3A5',
    }),
    '& .StyledStepIcon-completedIcon': {
      color: '#fff',
      zIndex: 1,
      fontSize: 18,
      width: 20,
      height: 20,
    },
    '& .StyledStepIcon-regular': {
      color: '#fff',
      fontSize: 12,
      fontWeight: 400,
    },
  })
);

const CustomStepIcon = (props) => {
  const { active, completed, className, icon } = props;
  return (
    <StyledStepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? (
        <Check className="StyledStepIcon-completedIcon" />
      ) : (
        <div className="StyledStepIcon-regular">{icon}</div>
      )}
    </StyledStepIconRoot>
  );
};

export function HorizontalLinearStepper({
  activeStep,
  mobileSteps,
  steps,
}: {
  activeStep: number;
  mobileSteps: string[];
  steps: string[];
}): React.ReactElement {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const stepDisplayCopy = isMobile ? mobileSteps : steps;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} connector={<StyledStepConnector />}>
        {stepDisplayCopy.map((label, index) => {
          const isActiveStep = index === activeStep;

          return (
            <Step key={label}>
              <StepLabel
                StepIconProps={{ completed: index < activeStep }}
                StepIconComponent={CustomStepIcon}
                sx={{
                  '.MuiStepLabel-label': {
                    fontSize: { xs: 15, sm: 18 },
                    fontWeight: isActiveStep ? 600 : 400,
                    transition: 'font-weight 0.2s ease-in-out',
                    marginLeft: '-4px',
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
