import { RadioGroup, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import ExpressInterestAPI from 'features/Connect/api/expressInterestAPI';
import GeolocationAPI from 'features/Connect/api/geolocationAPI';
import { useConnectFilterOptions } from 'hooks/data/useConnectFilterOptions';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'sharedComponents/Alert';
import { BasicSelect, MultiSelectWithSearch } from 'sharedComponents/Select';
import { Option } from 'sharedComponents/Select/types';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';
import { PreferencesFormData } from 'types/connectTypes';
import auth from 'utils/auth';
import { ConnectProfileFlowButtonGroup } from './SharedComponents/ConnectProfileFlowButtonGroup';
import { getGatedActionDisplayInfo, updateConnectFirstProfileSetupDateTime } from './utils';
import {
  ConnectProfileFlowContainer,
  ConnectProfileFlowFormContainer,
  ConnectProfileFlowPaperContainer,
  ConnectProfileFlowRadioCardMessageToggleStyles,
  ConnectProfileFlowTitle,
  ConnectProfileFlowSelectStyles,
  ConnectProfileFlowTextField,
} from './styles';
import RadioCardToggle from './SharedComponents/RadioCardToggle';
import { CONNECT_OPPORTUNITY_RADIO_GROUP } from './constants';

export function PreferencesForm(): React.ReactElement {
  const user = auth.getUser();
  const theme = useTheme();

  const { isLoadingFilterOptions, gradeOptions, subjectOptions, distanceOptions, schoolOptions } =
    useConnectFilterOptions();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getInitialSubjects = () => {
    if (user.preference.categories_connect) {
      return subjectOptions.filter((subject) =>
        user.preference.categories_connect.includes(subject.value)
      );
    }
    return [];
  };

  const getInitialGrades = () => {
    if (user.preference.grades) {
      return gradeOptions.filter((grade) => user.preference.grades.includes(Number(grade.value)));
    }
    return [];
  };

  const getInitialOpenToConnectOpportunities = () => {
    if (
      user.preference.open_to_connect_opportunities === null ||
      user.preference.open_to_connect_opportunities === true
    ) {
      return true;
    }
    return false;
  };

  const getInitialSchoolsToHideFrom = () => {
    if (user.preference.hide_user_from_schools_on_connect) {
      return schoolOptions.filter((school) =>
        user.preference.hide_user_from_schools_on_connect.includes(school.value)
      );
    }
    return [];
  };

  const isMissingRequiredFields = () => {
    const { openToConnectOpportunities, userLocation, subjects, grades } = userFormData;
    return openToConnectOpportunities && (!userLocation || !subjects.length || !grades.length);
  };

  const [userFormData, setUserFormData] = useState<PreferencesFormData>({
    openToConnectOpportunities: getInitialOpenToConnectOpportunities(),
    distanceFromSchool: user.preference.miles_within || 'Any',
    userLocation: user.preference.location,
    subjects: getInitialSubjects(),
    grades: getInitialGrades(),
    schoolsToHideFrom: getInitialSchoolsToHideFrom(),
  });
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);
  const [canUserSkipForm, setCanUserSkipForm] = useState<boolean>(
    !userFormData.openToConnectOpportunities
  );
  const [error, setError] = useState({
    distance: null,
    subject: null,
    grade: null,
  });
  const history = useHistory();
  const { flowDisplayInformation, pageDisplayInformation } =
    getGatedActionDisplayInfo(isMissingRequiredFields());

  const getPrimaryButtonText = () => {
    if (isMobile) {
      return pageDisplayInformation.primaryButtonLabelMobile;
    } else if (canUserSkipForm) {
      return 'Skip & Finish';
    }
    return pageDisplayInformation.primaryButtonLabel;
  };

  const resetErrorState = () =>
    setError({
      distance: null,
      subject: null,
      grade: null,
    });

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === CONNECT_OPPORTUNITY_RADIO_GROUP) {
      setUserFormData({
        ...userFormData,
        openToConnectOpportunities: JSON.parse(value),
      });
      return;
    }
  };

  const handleUserLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) resetErrorState();

    setUserFormData({
      ...userFormData,
      userLocation: event.target.value,
    });
  };

  const handleDistanceChange = (selectedOption: string) =>
    setUserFormData({
      ...userFormData,
      distanceFromSchool: selectedOption,
    });

  const handleGradeChange = (selectedOptions: Option[]) => {
    setError({ ...error, grade: null });
    setUserFormData({
      ...userFormData,
      grades: selectedOptions,
    });
  };
  const handleSubjectChange = (selectedOptions: Option[]) => {
    setError({ ...error, subject: null });
    setUserFormData({
      ...userFormData,
      subjects: selectedOptions,
    });
  };

  const handleSchoolsToHideFromChange = (selectedOptions: Option[]) => {
    setUserFormData({
      ...userFormData,
      schoolsToHideFrom: selectedOptions,
    });
  };

  const validateForm = async () => {
    const { openToConnectOpportunities, distanceFromSchool, userLocation, grades, subjects } =
      userFormData;
    let hasError = false;
    const errorState = {
      distance: null,
      subject: null,
      grade: null,
    };

    if (openToConnectOpportunities) {
      if (userLocation) {
        const response = await GeolocationAPI.getMapUrl({
          address: userLocation,
          radius: distanceFromSchool,
        });

        if (response[0] !== 200) {
          errorState.distance = 'Please provide a valid address or zip code.';
          hasError = true;
        }
      } else if (pageDisplayInformation.requiredFields.includes('distance')) {
        errorState.distance = 'Please enter a location.';
        hasError = true;
      }

      if (grades.length === 0 && pageDisplayInformation.requiredFields.includes('grade')) {
        errorState.grade = 'Please select at least one grade';
        hasError = true;
      }

      if (subjects.length === 0 && pageDisplayInformation.requiredFields.includes('subject')) {
        errorState.subject = 'Please select at least one subject';
        hasError = true;
      }

      if (hasError) {
        setError(errorState);
        return false;
      }
    }
    resetErrorState();
    return true;
  };

  const handleSaveAndContinue = async () => {
    setFormIsSubmitting(true);
    if ((await validateForm()) === false) {
      setFormIsSubmitting(false);
      return;
    }

    const {
      openToConnectOpportunities,
      distanceFromSchool,
      userLocation,
      grades,
      subjects,
      schoolsToHideFrom,
    } = userFormData;

    const userPreferences = {
      openToConnectOpportunities,
      subjects: subjects.map((subject) => String(subject.value)),
      // the api gets mad when we send an empty array for grades 🤷🏾‍♂️
      grades: grades.length ? grades.map((grade) => Number(grade.value)) : null,
      distanceFromSchool,
      userLocation,
      schoolsToHideFrom: schoolsToHideFrom.map((school) => String(school.value)),
    };

    try {
      await ExpressInterestAPI.updateUserPreferences(user.id, userPreferences);
      const updatedUser = await auth.getUserAsync();
      auth.setUser(updatedUser);
      setFormIsSubmitting(false);

      history.push(pageDisplayInformation.continueToUrl);
    } catch (error) {
      console.error('Error updating user profile', error);
      setFormIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isLoadingFilterOptions) return;

    const initialSubjects = getInitialSubjects();
    const initialGrades = getInitialGrades();
    const initialSchoolsToHideFrom = getInitialSchoolsToHideFrom();

    setUserFormData((currentFormData) => ({
      ...currentFormData,
      subjects: initialSubjects,
      grades: initialGrades,
      schoolsToHideFrom: initialSchoolsToHideFrom,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFilterOptions]);

  useEffect(() => {
    if (isLoadingFilterOptions) return;
    if (pageDisplayInformation.requiredFields.includes('distance')) {
      setCanUserSkipForm(false);
    } else {
      setCanUserSkipForm(!userFormData.openToConnectOpportunities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFormData]);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user);
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ConnectProfileFlowContainer>
      <HorizontalLinearStepper
        activeStep={pageDisplayInformation.step}
        steps={flowDisplayInformation.stepper.steps}
        mobileSteps={flowDisplayInformation.stepper.mobileSteps}
      />
      <ConnectProfileFlowPaperContainer elevation={4}>
        <ConnectProfileFlowFormContainer component="form">
          <ConnectProfileFlowTitle variant="h1">
            {pageDisplayInformation.headerText}
          </ConnectProfileFlowTitle>
          {isLoadingFilterOptions ? (
            <Stack>
              <CircularProgress sx={{ width: '100%', alignSelf: 'center' }} />
            </Stack>
          ) : (
            <Stack spacing={2} px={{ xs: 2, sm: 8 }} py={{ xs: 1, sm: 3 }}>
              <Alert>
                We&apos;ll use this information to show you the best matches for schools in your
                area.
              </Alert>
              <RadioGroup
                aria-labelledby="open-to-connect-opportunities-radio-buttons-group"
                name={CONNECT_OPPORTUNITY_RADIO_GROUP}
                value={userFormData.openToConnectOpportunities}
                onChange={handleFormChange}
              >
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={{
                    xs: 1,
                    sm: 2,
                  }}
                >
                  <RadioCardToggle
                    text="Yes, I’m open to being contacted by principals."
                    radioValue={'true'}
                    selectedValue={userFormData.openToConnectOpportunities}
                    sx={ConnectProfileFlowRadioCardMessageToggleStyles(theme)}
                  />
                  <RadioCardToggle
                    text="Don't allow principals to contact me yet."
                    radioValue={'false'}
                    selectedValue={userFormData.openToConnectOpportunities}
                    sx={ConnectProfileFlowRadioCardMessageToggleStyles(theme)}
                  />
                </Stack>
              </RadioGroup>
              {userFormData.openToConnectOpportunities && (
                <Stack spacing={2}>
                  <Box>
                    <QuestionLabel
                      required={userFormData.openToConnectOpportunities}
                      htmlFor="user-location"
                    >
                      Where do you want to teach?
                    </QuestionLabel>
                    <ConnectProfileFlowTextField
                      id="user-location"
                      name="userLocation"
                      hiddenLabel
                      placeholder="Enter a city, state, or zip"
                      fullWidth
                      defaultValue={userFormData.userLocation}
                      onChange={handleUserLocationChange}
                      error={!!error?.distance}
                      helperText={error?.distance}
                    />
                  </Box>
                  <Box>
                    <QuestionLabel required={false} htmlFor="distance-from-school">
                      Preferred distance from school?
                    </QuestionLabel>
                    <BasicSelect
                      id="distance-from-school"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      handleChange={handleDistanceChange}
                      options={distanceOptions}
                      values={[userFormData.distanceFromSchool]}
                      defaultValue={userFormData.distanceFromSchool}
                      sx={ConnectProfileFlowSelectStyles(theme)}
                    />
                  </Box>
                  <Box>
                    <QuestionLabel required={userFormData.openToConnectOpportunities}>
                      Preferred Subject(s)
                    </QuestionLabel>
                    <MultiSelectWithSearch
                      hiddenLabel
                      handleChange={handleSubjectChange}
                      displayName=""
                      options={subjectOptions}
                      values={userFormData.subjects}
                      placeholder={
                        userFormData.subjects.length >= 2
                          ? 'Subjects'
                          : userFormData.subjects.length === 1
                            ? ''
                            : 'Select subject(s)...'
                      }
                      sx={ConnectProfileFlowSelectStyles(theme)}
                      showError={!!error?.subject}
                      helperText={error?.subject}
                      tagLimit={1}
                    />
                  </Box>
                  <Box>
                    <QuestionLabel required={userFormData.openToConnectOpportunities}>
                      Preferred Grade Level(s)
                    </QuestionLabel>
                    <MultiSelectWithSearch
                      hiddenLabel
                      handleChange={handleGradeChange}
                      displayName=""
                      placeholder={
                        userFormData.grades.length >= 2
                          ? 'Grades'
                          : userFormData.grades.length === 1
                            ? ''
                            : 'Select grade level(s)...'
                      }
                      options={gradeOptions}
                      values={userFormData.grades}
                      sx={ConnectProfileFlowSelectStyles(theme)}
                      showError={!!error?.grade}
                      helperText={error?.grade}
                      tagLimit={1}
                    />
                  </Box>
                  <Box>
                    <QuestionLabel>
                      Hide profile from the following school(s)
                      <InfoText>
                        Use this option to keep your current school from viewing your profile.
                      </InfoText>
                    </QuestionLabel>
                    <MultiSelectWithSearch
                      hiddenLabel
                      handleChange={handleSchoolsToHideFromChange}
                      displayName=""
                      options={schoolOptions}
                      values={userFormData.schoolsToHideFrom}
                      placeholder={
                        userFormData.schoolsToHideFrom.length >= 2
                          ? 'Schools'
                          : userFormData.schoolsToHideFrom.length === 1
                            ? ''
                            : 'Select school(s)...'
                      }
                      sx={ConnectProfileFlowSelectStyles(theme)}
                      tagLimit={1}
                      hasSubLabel={true}
                      subLabelKeys={['address.city']}
                    />
                  </Box>
                </Stack>
              )}
            </Stack>
          )}
        </ConnectProfileFlowFormContainer>
      </ConnectProfileFlowPaperContainer>
      <ConnectProfileFlowButtonGroup
        dataTestId={''}
        primaryButton={{
          dataTestId: '',
          primaryButtonLabel: getPrimaryButtonText(),
          primaryAction: handleSaveAndContinue,
          disabled:
            userFormData.openToConnectOpportunities &&
            (!!error.distance || !!error.grade || !!error.subject),
          isLoading: formIsSubmitting,
        }}
        secondaryButton={{
          dataTestId: '',
          secondaryButtonLabel: 'Back',
          secondaryAction: () => history.push(pageDisplayInformation.backUrl),
        }}
      />
    </ConnectProfileFlowContainer>
  );
}

const QuestionLabel = styled(InputLabel)(({ theme }) => ({
  textWrap: 'wrap',
  color: theme.palette.text.primary,
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  color: theme.palette.text.tertiary,
  paddingTop: theme.spacing(0.5),
}));
