import { ChangeEvent } from 'react';
import { Job, District, School, User, HRBPAssignment, JobErrors } from 'types/types';
import { HrbpAssigneesData } from 'containers/TalentMarketplace.types';
import { UpdateFieldInfo } from 'features/RoleForm/types/types';
import {
  CustomScorecard,
  DistrictUserJob,
  NewApplicationStatus,
  QuestionBankQuestion,
  ResponseError,
} from 'containers/DistrictUserJobCreate/types';
import { JobboardOptions } from 'features/RoleForm/types/types';
import { JobviewJob } from 'features/JobView/types';

export const ALL_APPLICANTS = 'all-applicants';
export const INTERNAL_ONLY = 'internal-only';

export interface AssignmentContainerProps {
  firstRow?: boolean;
  lastRow?: boolean;
}

export interface ContainerProps {
  isActive?: boolean;
  highlightColor?: string;
}

export type DistrictAndSuperAdmin = Omit<HrbpAssigneesData, 'linkedUsers'> & {
  linked_users?: string[];
};

export interface HRBPAssignmentProps {
  districtAndSuperAdmins: DistrictAndSuperAdmin[] | User[];
  hasError: boolean | string;
  hrbpAssignments: HRBPAssignment[];
  job: Job;
  onSave: (h: HRBPAssignment[]) => void;
  schoolOptions: SchoolOption[] | School[];
  schools: number[] | School[];
}

export type InternalOnlyStatus = typeof ALL_APPLICANTS | typeof INTERNAL_ONLY;

export interface SchoolOption {
  district_id?: number;
  group?: number;
  id: string | number;
  isGroupHeader: boolean;
  name: string;
  schools: number[];
  geocoded_location?: string;
  location?: string;
  school_groups?: unknown[];
}

export interface SelectionListProps {
  showOptions?: boolean;
}

export interface VisibilitySelectorProps {
  job: Job;
  updateField: (e: ChangeEvent<HTMLInputElement>) => void;
  district: District;
}

export type JobDetailsProps = {
  districtAndSuperAdmins?: DistrictAndSuperAdmin[];
  isDistrictAdmin: boolean;
  job: Job;
  updateJob: (job: Job) => void;
  updateField: (e: UpdateFieldInfo | ChangeEvent<HTMLInputElement>) => void;
  updateJobDate: (field: string, value: string) => void;
  resetDate: (field: string) => void;
  errors: JobErrors;
  isTemplate: boolean;
};

export type EmploymentTypeValue = 0.5 | 0.75 | 1;
export type SalaryTypeValue = 1 | 2 | 3 | 4 | 5;

type JobClosureNotificationAlert = {
  alert_id: number;
  type: 'candidate_application_status';
  context: {
    candidate_status: string;
    threshold: number;
  };
};

export type JobClosureNotification = {
  role_notification_id: number;
  subscribers: number[];
  type: 'job_closure';
  alerts: JobClosureNotificationAlert[];
};

export interface JobEditProps {
  activelySubmitting?: boolean;
  allRolesForMerge?: Job[];
  apiResponseErrors: ResponseError;
  applicationStatuses?: NewApplicationStatus[];
  clearAPIResponseErrors: () => void;
  customScorecards: CustomScorecard[];
  customScorecardsSelected: CustomScorecard[];
  districtAndSuperAdmins?: User[];
  districtUsers?: User[];
  isEditing?: boolean;
  job: JobviewJob | DistrictUserJob;
  jobBoardOptions?: JobboardOptions[];
  onDelete?: () => void;
  onSave: (childRole: any) => void;
  onSaveLabel?: string;
  onSaveRoleForm?: () => void;
  onSubmit: (childRole: any) => void;
  onSubmitLabel?: string;
  pageTitle?: string;
  questionBank: QuestionBankQuestion[];
  schoolOptions?: School[];
  schools?: School[];
  setCustomScorecardsSelected?: () => void;
  statusColor?: string;
  wasSuccessful?: boolean;
}
