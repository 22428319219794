import { Component } from 'react';
import PropTypes from 'prop-types';

import { colorMapping } from 'utils/enums';
import iconUpCaretGreen from 'assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from 'assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from 'assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from 'assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from 'assets/tooltip.svg';
import { nimbleTheme } from 'theme';
import { ATSCandidatesListFilterTestIds } from 'data-testids/ATS';
import { replaceUnderscoresAndSpacesWithHyphens } from '../../e2e/utils/formatTestIds';
import { Tooltip } from 'sharedComponents/Tooltip';

const heyLint = true;

export default class ListFilterDropdown extends Component {
  static propTypes = {
    updateMultiSelectFilter: PropTypes.func.isRequired,
    displayName: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    selectAll: PropTypes.func,
    clearAll: PropTypes.func,
    // optional message to display if there are no options
    // e.g. "You don't have any tasks yet"
    emptyOptionsMessage: PropTypes.string,
    isSubFilter: PropTypes.bool,
    isTemplateOrRolePage: PropTypes.bool,
  };

  static defaultProps = {
    isSubFilter: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  toggleOptions = () => {
    this.setState({ showOptions: !this.state.showOptions });
  };

  selectAll = (e, fieldName, options) => {
    e.stopPropagation();
    this.props.selectAll(fieldName, options);
  };

  clearAll = (e, fieldName) => {
    e.stopPropagation();
    this.props.clearAll(fieldName);
  };

  updateMultiSelectFilter = (fieldName, value, active) => {
    fieldName === 'statuses_available'
      ? this.props.updateMultiSelectFilter(value, fieldName, active)
      : this.props.updateMultiSelectFilter(fieldName, value, active, this.props.options.length);
  };

  render() {
    const optionSet = new Set();
    this.props.values.forEach((o) => {
      optionSet.add(o);
    });
    // This is used in the case of statuses_available dropdown
    if (this.props.isTemplateOrRolePage) {
      var optionsArray = [...optionSet];
    }
    const { displayName, fieldName, options, values } = this.props;

    // if there is one filter option selected (e.g. Resume Review), show that name. If there are
    // multiple selected, show 'multiple'. otherwise show the displayName (e.g. Status).
    // some of the enums have different labeling (-_-), so account for differences.
    const valueName =
      fieldName === 'statuses_available' || fieldName === 'status_list' || fieldName === 'cred_list'
        ? 'id'
        : 'value';

    // default is to have all filters selected, so we only need to show
    // the filter as active if they change something, which would make both allSelected
    // and noneSelected false.
    let allSelected = true;
    // if any option is not selected, allSelected is false
    options.forEach((o) => {
      if (!optionSet.has(o[valueName])) {
        allSelected = false;
      }
    });
    let noneSelected = values.length === 0;
    let isActive = !(allSelected || noneSelected) && !this.props.isTemplateOrRolePage;
    return (
      <div
        className={`new-cl-filter ${this.props.fieldName} ${isActive ? 'cl-active' : ''} ${
          this.props.isSubFilter ? 'sub-filter' : ''
        } ${this.props.isTemplateOrRolePage && 'job-edit-filter'}`}
      >
        <div
          className="cl-filter-header"
          onClick={this.toggleOptions}
          data-testid={`${ATSCandidatesListFilterTestIds.FILTER}-${replaceUnderscoresAndSpacesWithHyphens(this.props.fieldName)}`}
        >
          <p className={isActive ? 'cl-active' : ''}>{displayName}</p>
          <img
            src={
              this.state.showOptions && isActive
                ? iconUpCaretGreen
                : !this.state.showOptions && isActive
                  ? iconDownCaretGreen
                  : this.state.showOptions
                    ? iconUpCaret
                    : iconDownCaret
            }
            alt=""
          />
        </div>
        <div className={`relative selection-list ${this.state.showOptions ? 'show' : ''}`}>
          {this.props.isTemplateOrRolePage && (
            <div className="statuses-explanatory-text">
              <div className="mb1">
                <img className="" src={InfoIcon} alt="tooltip" />
              </div>
              Deselecting a status for this job means that users cannot move the candidates into
              that status when screening for this job.
              <div className="mt1">This does not affect other job postings.</div>
            </div>
          )}
          {options.length === 0 && this.props.emptyOptionsMessage ? (
            <div className="option select-all">{this.props.emptyOptionsMessage}</div>
          ) : this.props.selectAll && this.props.clearAll ? (
            <div className="option select-all">
              <span
                className="drop-select-all"
                onClick={(e) => this.selectAll(e, fieldName, options)}
                data-testid="select-all-status-button"
              >
                Select All
              </span>
              <span
                className="drop-select-all"
                onClick={(e) => this.clearAll(e, fieldName)}
                data-testid={`${ATSCandidatesListFilterTestIds.STATUS_CLEAR_ALL_BUTTON}-${replaceUnderscoresAndSpacesWithHyphens(this.props.fieldName)}`}
              >
                Clear All
              </span>
            </div>
          ) : null}
          {options.map((o, i) => {
            const disabledBecauseOfAssociatedNotification = this.props.queryCache?.includes(o.id);
            const disabled =
              (this.props.isTemplateOrRolePage && o.status_type === 4) ||
              disabledBecauseOfAssociatedNotification;

            return (
              <div
                key={i}
                className="option"
                data-testid={replaceUnderscoresAndSpacesWithHyphens(
                  `${ATSCandidatesListFilterTestIds.FILTER_OPTION}-${o.label}`
                )}
              >
                <label
                  style={{ display: 'flex', alignItems: 'center' }}
                  className={`container ${disabled ? 'option-disabled' : ''}`}
                >
                  <input
                    type="checkbox"
                    checked={
                      this.props.isTemplateOrRolePage
                        ? optionsArray.filter((oa) => oa.id === o.id).length > 0
                        : optionSet.has(o[valueName])
                    }
                    onChange={() =>
                      this.updateMultiSelectFilter(
                        fieldName,
                        o[valueName],
                        optionSet.has(o[valueName])
                      )
                    }
                    disabled={disabled}
                    className={disabled ? 'option-disabled' : ''}
                  />
                  <span
                    className="checkmark"
                    style={{
                      backgroundColor: disabled ? nimbleTheme.palette.text.disabled : null,
                    }}
                  />
                  {fieldName === 'statuses_available' ||
                    (fieldName === 'status_list' && (
                      <span className={`status-circle ${colorMapping[o.color]} inline-block`} />
                    ))}
                  {o.label} &nbsp;
                  {disabledBecauseOfAssociatedNotification && (
                    <Tooltip title="This can't be deselected because there is an associated notification">
                      <HelpOutlineIcon size="small" />
                    </Tooltip>
                  )}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
