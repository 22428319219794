import { TextField, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Label } from 'sharedComponents/Label';
import { ConnectMarketingInputAndButtonProps } from '../types';
import { ConnectMarketingInputAndButtonDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useTheme } from '@mui/material/styles';

export function ConnectMarketingInputAndButton({
  onSubmit,
  isLoading,
  email,
  setEmail,
  isActive,
}: ConnectMarketingInputAndButtonProps): React.ReactElement {
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <ConnectMarketingInputAndButtonContainer>
      <InputLabelBox>
        <Label labelText={'Enter your personal email'} sx={labelStyles(isMobile, theme)} />
        <StyledTextField
          inputProps={{ style: { padding: 0 } }}
          placeholder={'Personal email address'}
          onChange={(evt) => setEmail(evt.target.value)}
          value={email}
          data-testid={ConnectMarketingInputAndButtonDataTestIds.EMAIL_INPUT}
        />
      </InputLabelBox>
      <Divider />
      <PrimaryButton
        onClick={onSubmit}
        size="small"
        isLoading={isLoading}
        disabled={!isActive}
        dataTestId={ConnectMarketingInputAndButtonDataTestIds.SIGN_UP_BUTTON}
        sx={primaryButtonStyles(isMobile, theme)}
      >
        Get Started
      </PrimaryButton>
    </ConnectMarketingInputAndButtonContainer>
  );
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: '#6B7280',
  lineHeight: '20px',
  padding: 'none',
  fontStyle: 'normal',
  fontSize: '16px',
  '& fieldset': { border: 'none' },

  [theme.breakpoints.down('md')]: {
    width: '153px',
    fontSize: '11.333px',
  },
}));

const ConnectMarketingInputAndButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: '12px solid #f4f6ff',
  borderRadius: theme.shape.borderRadius * 8,
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 8px 8px 32px',
  minHeight: '72px',
  gap: theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

const InputLabelBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const Divider = styled(Box)(({ theme }) => ({
  border: '1px solid #E5E7EB',
  color: '#6B7280',

  [theme.breakpoints.down('md')]: {
    height: '22.67px',
  },
  [theme.breakpoints.up('md')]: {
    height: '32px',
  },
}));

const labelStyles = (isMobile, theme) => ({
  color: theme.palette.black.darker,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: isMobile ? '8.5px' : '12px',
  paddingBottom: 'none',
});

const primaryButtonStyles = (isMobile, theme) => ({
  color: theme.palette.white.main,
  paddingY: isMobile ? '22.67px' : '15px',
  paddingX: isMobile ? '10.62px' : theme.spacing(4),
  maxWidth: isMobile ? '110px' : '156px',
  maxHeight: isMobile ? '40px' : '56px',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: isMobile ? '11.33px' : '16px',
  borderRadius: isMobile ? '11.33px' : theme.shape.borderRadius,
  background: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  whiteSpace: 'nowrap',
});
