import { employmentTypeValues, hiringSeasons, salarytype } from 'utils/enums';
import { JobViewSubcategory, HiringSeasonOption } from 'features/JobView/types';

export const onet_codes = () => [
  { value: '25-9031.00', label: 'Instructional Coach or Curriculum Specialist' },
  { value: '25-2011.00', label: 'Lead Teacher - Preschool (Except Special Education)' },
  { value: '25-2012.00', label: 'Lead Teacher - Kindergarten (Except Special Education)' },
  { value: '25-2021.00', label: 'Lead Teacher - Elementary (Except Special Education)' },
  {
    value: '25-2022.00',
    label: 'Lead Teacher - Middle School (Except Special and Career/Technical Education)',
  },
  {
    value: '25-2031.00',
    label: 'Lead Teacher - High School (Except Special and Career/Technical Education)',
  },
  { value: '25-2051.00', label: 'Special Education Teacher - Preschool' },
  { value: '25-2055.00', label: 'Special Education Teacher - Kindergarten' },
  { value: '25-2056.00', label: 'Special Education Teacher - Elementary School' },
  { value: '25-2057.00', label: 'Special Education Teacher - Middle School' },
  { value: '25-2058.00', label: 'Special Education Teacher - High School' },
  { value: '25-2059.00', label: 'Special Education Teacher - All Other' },
  { value: '25-9043.00', label: 'Paraprofessional or Special Education Teaching Assistant' },
  { value: '25-9042.00', label: 'Teaching Assistant (Except Special Education)' },
  { value: '25-9049.00', label: 'Teaching Assistant - All Other' },
  { value: '25-2023.00', label: 'Career/Technical Education Teacher - Middle School' },
  { value: '25-2032.00', label: 'Career/Technical Education Teacher - Secondary School' },
  { value: '25-3021.00', label: 'Enrichment Teacher' },
  { value: '25-3031.00', label: 'Substitute teacher' },
  { value: '25-3041.00', label: 'Tutor' },
  { value: '25-4022.00', label: 'Librarian' },
  { value: '25-4031.00', label: 'Library Assistant' },
  { value: '25-9099.00', label: 'Library Worker - All Other' },
  { value: '25-2059.01', label: 'Adapted Physical Education Specialist' },
  { value: '25-3011.00', label: 'Adult Basic Education or ESL Teacher' },
  { value: '25-3099.00', label: 'Other Teaching Role' },
];

export const SALARY_TYPE = salarytype().reduce(
  (obj: Record<number, string>, item: JobViewSubcategory) => {
    obj[item.value] = item.label;
    return obj;
  },
  {}
);

export const EMPLOYMENT_TYPE = employmentTypeValues().reduce(
  (obj: Record<number, string>, item: JobViewSubcategory) => {
    obj[item.value] = item.label;
    return obj;
  },
  {}
);

export const HIRING_SEASON = hiringSeasons(true, true).reduce(
  (obj: Record<number, string>, item: HiringSeasonOption) => {
    obj[item.value] = item.label_new;
    return obj;
  },
  {}
);
