import tndoeStateLogo from 'assets/state_logos/tn-doe-logo.png';
import modoeStateLogo from 'assets/state_logos/mo-state-seal.png';
import missouriDefaultMap from 'assets/connect/connect-default-map.png';
import tennesseeDefaultMap from 'assets/connect/connect-tennessee-default-map2.png';
import indianaDefaultMap from 'assets/connect/connect-indiana-default-map.png';

export const nimbleConnectUrlFlag = 'connect';

export const CAROUSEL_SLIDE_TYPES = {
  IMAGE_WITH_TEXT: 'image_with_text',
  QUOTE: 'quote',
};

export const CONNECT_JOBBOARD_STATES = {
  INDIANA: {
    stateName: 'INDIANA',
    stateCode: 'IN',
    urlParam: 'indiana',
    logo: null,
    defaultUserLocation: 'Indianapolis, IN',
    defaultMapImage: indianaDefaultMap,
    label: 'Indiana',
  },
  MISSOURI: {
    stateName: 'MISSOURI',
    stateCode: 'MO',
    urlParam: 'missouri',
    logo: modoeStateLogo,
    defaultMapImage: missouriDefaultMap,
    defaultUserLocation: 'St. Louis, MO',
    label: 'Missouri',
  },
  TENNESSEE: {
    stateName: 'TENNESSEE',
    stateCode: 'TN',
    urlParam: 'tennessee',
    logo: tndoeStateLogo,
    defaultMapImage: tennesseeDefaultMap,
    defaultUserLocation: 'Memphis, TN',
    label: 'Tennessee',
  },
};
