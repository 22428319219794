import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { styled, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';

import ExpressInterestAPI from 'features/Connect/api/expressInterestAPI';
import UserSavedSchoolAPI from 'features/Connect/api/userSavedSchoolAPI';
import auth from 'utils/auth';

import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { ConnectProfileFlowButtonGroup } from './SharedComponents/ConnectProfileFlowButtonGroup';
import RadioCardToggle from './SharedComponents/RadioCardToggle';
import { getGatedActionDisplayInfo, updateConnectFirstProfileSetupDateTime } from './utils';
import { ConnectCandidatePreferencesDataTestIds } from 'data-testids/ConnectDataTestIds';
import {
  ConnectProfileFlowContainer,
  ConnectProfileFlowFormContainer,
  ConnectProfileFlowPaperContainer,
  ConnectProfileFlowTitle,
  ConnectProfileFlowSelectStyles,
  ConnectProfileFlowTextField,
} from './styles';
import { MultiSelectWithSearch } from 'sharedComponents/Select';
import { CONNECT_PROFILE_STATE_PREFERENCE_OPTIONS } from 'features/Connect/utils/connectConstants';

export function CreateAndUpdateProfileForm(): React.ReactElement {
  const user = auth.getUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { created, modified, open_to_opportunities } = user.preference;
  const createdTime = new Date(created);
  const modifiedTime = new Date(modified);
  // we're using seconds because the created and modified timestamp milliseconds are slighly off when a user is created
  const createdTimeInSeconds = Math.floor(createdTime.getTime() / 1000);
  const modifiedInSeconds = Math.floor(modifiedTime.getTime() / 1000);
  const differenceInSeconds = Math.abs(createdTimeInSeconds - modifiedInSeconds);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const gatedAction = searchParams.get('action');
  const gatedActionSchool = searchParams.get('school');

  const getInitialSelectedOpportunity = () => {
    if (differenceInSeconds <= 2) {
      return 'yes';
    } else {
      return open_to_opportunities ? 'yes' : 'no';
    }
  };

  const getInitialSelectedStateOptions = (stateOptions, statePreferences) => {
    return stateOptions.filter((state) => statePreferences?.includes(state.value));
  };
  const getSelectedStateOptionsValues = (selectedOptions) => {
    if (!selectedOptions) return [];
    return selectedOptions.map((state) => state.value);
  };
  const [selectedStateOptions, setSelectedStateOptions] = useState(
    getInitialSelectedStateOptions(CONNECT_PROFILE_STATE_PREFERENCE_OPTIONS, user.preference.states)
  );

  const [userFormData, setUserFormData] = useState({
    firstName: user.first_name || '',
    lastName: user.last_name || '',
    phoneNumber: user.profile.phone_cell || '',
    selectedOpportunity: getInitialSelectedOpportunity(),
    statePreferences: getSelectedStateOptionsValues(selectedStateOptions) || [],
  });
  const [error, setError] = useState({
    firstName: null,
    lastName: null,
    phoneNumber: null,
    selectedOpportunity: null,
    statePreferences: null,
  });
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const history = useHistory();
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();

  const sortedStateOptions = [...CONNECT_PROFILE_STATE_PREFERENCE_OPTIONS].sort((a, b) => {
    if (a.isActive && !b.isActive) return -1;
    if (!a.isActive && b.isActive) return 1;
    return a.label.localeCompare(b.label);
  });

  const resetErrorState = () =>
    setError({
      firstName: null,
      lastName: null,
      phoneNumber: null,
      selectedOpportunity: null,
      statePreferences: null,
    });

  const getStateGroupLabels = (option) =>
    option.isActive
      ? 'ACTIVE STATES'
      : "INACTIVE STATES (we'll let you know via email when we launch)";

  const getFormTitle = () => {
    return differenceInSeconds <= 2 ? 'Create your profile' : 'Update your profile';
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) resetErrorState();

    if (event.target.name === 'controlled-radio-buttons-group') {
      setUserFormData({
        ...userFormData,
        selectedOpportunity: event.target.value,
      });
      return;
    }

    setUserFormData({
      ...userFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleStatePreferenceInputChange = (selectedOptions) => {
    if (error) resetErrorState();
    setSelectedStateOptions(selectedOptions);
    setUserFormData({
      ...userFormData,
      statePreferences: getSelectedStateOptionsValues(selectedOptions),
    });
  };

  const validateForm = () => {
    const { firstName, lastName, phoneNumber, statePreferences } = userFormData;
    let hasError = false;
    const errorState = {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      selectedOpportunity: null,
      statePreferences: null,
    };

    if (!firstName) {
      errorState.firstName = 'Please fill out your first name';
      hasError = true;
    }

    if (!lastName) {
      errorState.lastName = 'Please fill out your last name';
      hasError = true;
    }

    // TODO: validate phone number like we do in ATS
    if (phoneNumber && phoneNumber.length < 10) {
      errorState.phoneNumber = 'Please provide a valid phone number or leave it blank';
      hasError = true;
    }

    if (!statePreferences.length) {
      errorState.statePreferences = 'Please select at least one state preference';
      hasError = true;
    }

    if (hasError) {
      setError(errorState);
      return false;
    }
    resetErrorState();
    return true;
  };

  useEffect(() => {
    const saveSchool = async () => {
      const savedSchools = await UserSavedSchoolAPI.getSchoolsUserHasSaved();
      const savedSchoolIds = savedSchools.map((s) => s.school_nces_id);
      const isSchoolSaved = savedSchoolIds.includes(gatedActionSchool);
      if (!isSchoolSaved) {
        await UserSavedSchoolAPI.saveSchool(gatedActionSchool);
      }
    };
    if (gatedAction === ConnectGatedActions.SAVE_SCHOOL && gatedActionSchool) saveSchool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user);
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSaveAndContinue = async () => {
    const { firstName, lastName, phoneNumber, selectedOpportunity, statePreferences } =
      userFormData;

    if (!validateForm()) return;

    const userTalentProfile = {
      userId: user.id,
      firstName,
      lastName,
      phoneCell: phoneNumber,
      openToOpportunities: selectedOpportunity === 'yes',
      preferenceStates: statePreferences,
    };

    try {
      setFormIsSubmitting(true);
      await ExpressInterestAPI.updateTalentProfile(userTalentProfile);
      const updatedUser = await auth.getUserAsync();
      auth.setUser(updatedUser);
      setFormIsSubmitting(false);

      history.push(pageDisplayInformation.continueToUrl);
    } catch (error) {
      setFormIsSubmitting(false);
      console.error('Error updating user profile', error);
    }
  };

  return (
    <ConnectProfileFlowContainer>
      <HorizontalLinearStepper
        activeStep={pageDisplayInformation.step}
        steps={flowDisplayInformation.stepper.steps}
        mobileSteps={flowDisplayInformation.stepper.mobileSteps}
      />
      <ConnectProfileFlowPaperContainer elevation={4}>
        <ConnectProfileFlowFormContainer component="form">
          <ConnectProfileFlowTitle
            data-testid={ConnectCandidatePreferencesDataTestIds.COMPLETE_PROFILE_TEXT}
            variant="h1"
          >
            {pageDisplayInformation.headerText || getFormTitle()}
          </ConnectProfileFlowTitle>
          <Stack spacing={2} px={{ xs: 2, sm: 8 }} py={{ xs: 1, sm: 3 }}>
            <Box>
              <QuestionLabel
                htmlFor="first-name"
                required={pageDisplayInformation.requiredFields.includes('firstName')}
                aria-required
              >
                First Name
              </QuestionLabel>
              <ConnectProfileFlowTextField
                hiddenLabel
                id="first-name"
                name="firstName"
                placeholder="Enter your first name"
                fullWidth
                size="medium"
                defaultValue={userFormData.firstName}
                onChange={handleFormChange}
                error={!!error.firstName}
                helperText={error.firstName}
              />
            </Box>
            <Box>
              <QuestionLabel
                htmlFor="last-name"
                required={pageDisplayInformation.requiredFields.includes('lastName')}
                aria-required
              >
                Last Name
              </QuestionLabel>
              <ConnectProfileFlowTextField
                hiddenLabel
                id="last-name"
                name="lastName"
                placeholder="Enter your last name"
                fullWidth
                size="medium"
                defaultValue={userFormData.lastName}
                onChange={handleFormChange}
                error={!!error.lastName}
                helperText={error.lastName}
              />
            </Box>
            <Box>
              <QuestionLabel htmlFor="last-name">Phone Number</QuestionLabel>
              <ConnectProfileFlowTextField
                hiddenLabel
                id="phone-number"
                name="phoneNumber"
                placeholder="+1 (555) 000-0000"
                fullWidth
                inputProps={{
                  minLength: 10,
                }}
                size="medium"
                defaultValue={userFormData.phoneNumber}
                onChange={handleFormChange}
                error={!!error.phoneNumber}
                helperText={error.phoneNumber}
              />
            </Box>
            <Box>
              <QuestionLabel htmlFor="controlled-radio-buttons-group" required aria-required>
                Where are you in the job search process?
              </QuestionLabel>
              <RadioGroup
                aria-labelledby="open-to-opportunities-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={userFormData.selectedOpportunity}
                onChange={handleFormChange}
              >
                <Stack
                  direction={{
                    xs: 'column',
                    sm: 'row',
                  }}
                  spacing={{
                    xs: 1,
                    sm: 2,
                  }}
                >
                  <RadioCardToggle
                    width={isMobile ? '100%' : '50%'}
                    text="Open to work"
                    radioValue="yes"
                    selectedValue={userFormData.selectedOpportunity}
                  />
                  <RadioCardToggle
                    width={isMobile ? '100%' : '50%'}
                    text="Not open to work"
                    radioValue="no"
                    selectedValue={userFormData.selectedOpportunity}
                  />
                </Stack>
              </RadioGroup>
            </Box>
            <Box>
              <QuestionLabel htmlFor="state-preferences" required aria-required>
                In which state(s) are you looking for work?
              </QuestionLabel>
              <MultiSelectWithSearch
                values={selectedStateOptions}
                options={sortedStateOptions}
                handleChange={(states) => handleStatePreferenceInputChange(states)}
                optionGroupingFunction={getStateGroupLabels}
                renderGroupHeader={(params) => (
                  <div key={params.key}>
                    <div style={GroupHeaderStyles(theme)}>{params.group}</div>
                    {Array.isArray(params.children) ? (
                      params.children.map((child, index) => (
                        <div key={`group-${params.group}-option-${index}`}>{child}</div>
                      ))
                    ) : (
                      <div>{params.children}</div>
                    )}
                  </div>
                )}
                tagLimit={isMobile ? 2 : 3}
                placeholder={
                  selectedStateOptions.length >= 2
                    ? 'State(s)'
                    : selectedStateOptions.length === 1
                      ? ''
                      : 'Select state(s)...'
                }
                size="medium"
                sx={ConnectProfileFlowSelectStyles(theme)}
                showError={!!error?.statePreferences}
                helperText={error?.statePreferences}
              />
            </Box>
          </Stack>
        </ConnectProfileFlowFormContainer>
      </ConnectProfileFlowPaperContainer>
      <ConnectProfileFlowButtonGroup
        dataTestId=""
        primaryButton={{
          dataTestId: '',
          primaryButtonLabel: isMobile
            ? pageDisplayInformation?.primaryButtonLabelMobile
            : pageDisplayInformation?.primaryButtonLabel,
          primaryAction: handleSaveAndContinue,
          disabled:
            !!error.firstName ||
            !!error.lastName ||
            !!error.phoneNumber ||
            !!error.statePreferences,
          isLoading: formIsSubmitting,
        }}
      />
    </ConnectProfileFlowContainer>
  );
}

const QuestionLabel = styled(InputLabel)({
  textWrap: 'wrap',
});

const GroupHeaderStyles = (theme: Theme) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderTop: '1px solid #ddd',
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.text.light,
});
