import { getJobViewUrl, shouldSeePublicLink } from '../utils';
import { JobViewMetadataProps } from '../types';
import { MetadataContainer, RightBottom, RightTop } from '../styles';
import { JobViewPublicShareLink } from './JobViewPublicShareLink';
import auth from 'utils/auth';
import { JobViewDetails } from './JobViewDetails';

export const JobViewMetadata: React.FC<JobViewMetadataProps> = ({ job }) => {
  const districtRoleId = auth.isDistrictUser() ? job.id : job.district_role?.id;
  const jobLink = getJobViewUrl(districtRoleId);

  return (
    <MetadataContainer>
      {shouldSeePublicLink(job) && (
        <RightTop>
          <JobViewPublicShareLink link={jobLink} />
        </RightTop>
      )}
      <RightBottom>
        <JobViewDetails job={job} />
      </RightBottom>
    </MetadataContainer>
  );
};
