import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material';
import { BasicSelect } from 'sharedComponents/Select';
import { Input } from 'sharedComponents/Input';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { JobDiscoveryFilterContainerProps } from 'types/connectTypes';
import { useEffect, useState } from 'react';
import GeolocationAPI from '../../../api/geolocationAPI';
import { ConnectFilterName } from 'features/Connect/utils/connectEnums';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useStateParam } from '../../ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

export const JobDiscoveryLocationFilter: React.FC<JobDiscoveryFilterContainerProps> = ({
  filterValues,
  filterOptions,
  distanceDisplayName,
  locationDisplayName,
  locationChangeHandler,
  actions,
  setShowMap,
  setMapUrl,
  mapUrl,
  isLoading,
}) => {
  const jobboardState = useStateParam();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const [selectedDistance, setSelectedDistance] = useState<string>('');
  const [locationInput, setLocationInput] = useState<string>(
    CONNECT_JOBBOARD_STATES[jobboardState]?.defaultUserLocation
  );
  const [locationLongitude, setLocationLongitude] = useState<string>('');
  const [locationLatitude, setLocationLatitude] = useState<string>('');
  const [showMapInputError, setShowMapInputError] = useState<boolean>(false);
  const [showMapSelectError, setShowMapSelectError] = useState<boolean>(false);

  const validateLocationAndDistance = () => {
    const isInputError = !locationInput && !!selectedDistance;
    const isSelectError = !!locationInput && !selectedDistance;

    setShowMapInputError(isInputError);
    setShowMapSelectError(isSelectError);

    return !isInputError && !isSelectError;
  };

  const handleAddressSearch = async (event, location, distance) => {
    event.preventDefault();

    if (!validateLocationAndDistance()) {
      actions.setIsLoading(false);
      return;
    }

    actions.setIsLoading(true);
    const data = { address: location, radius: distance, isMobile };
    const response = await GeolocationAPI.getMapUrl(data);

    if (response[0] === 200) {
      const mapData = response[1];
      setShowMap(!!mapData.map_url);
      setLocationLatitude(mapData.latitude);
      setLocationLongitude(mapData.longitude);
      setMapUrl(mapData.map_url);
      setShowMapInputError(false);
      setShowMapSelectError(false);
    } else {
      const errorType = response[1].error;
      setShowMapInputError(errorType === 'invalid address' || errorType === 'address is required');
      setShowMapSelectError(!data.radius);
      setShowMap(false);
      setMapUrl('');
    }
    actions.setIsLoading(false);
  };

  useEffect(() => {
    if (mapUrl && mapUrl.length > 0) {
      setShowMap(true);
    }

    const distanceRadiusMiles = selectedDistance !== 'Any' ? selectedDistance : '';
    const distanceIsActive = selectedDistance !== 'Any';

    locationChangeHandler({
      distance_radius_miles: distanceRadiusMiles,
      location_latitude: locationLatitude,
      location_longitude: locationLongitude,
    });

    actions.setFilterIsActive(ConnectFilterName.LOCATION, true);
    actions.setFilterIsActive(ConnectFilterName.DISTANCE_RADIUS_MILES, distanceIsActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapUrl]);

  return (
    <Grid container spacing={1} rowGap={0.5}>
      <Grid item xs={12} md={6} lg={6}>
        <Input
          label="Location"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.LOCATION_INPUT}
          placeholder={locationDisplayName}
          onChange={(e) => setLocationInput(e.target.value)}
          value={locationInput}
          size="small"
          sx={inputStyles(theme)}
          hasError={showMapInputError}
        />
        {showMapInputError && (
          <Typography sx={errorTextStyles(theme)}>
            Please enter a valid address or zipcode
          </Typography>
        )}
      </Grid>
      <Grid container item direction={'row'} spacing={1} xs={12} md={6} lg={6}>
        <Grid item xs={9} lg={7}>
          <BasicSelect
            displayName={distanceDisplayName}
            dataTestId={ConnectJobDiscoveryFiltersDataTestIds.DISTANCE_FILTER}
            handleChange={(distance) => setSelectedDistance(distance)}
            options={filterOptions}
            sx={selectStyles(theme)}
            size="small"
            values={filterValues.selectedDistance}
            showError={showMapSelectError}
          />
          {showMapSelectError && (
            <Typography sx={errorTextStyles(theme)}>Please select an option</Typography>
          )}
        </Grid>
        <Grid item xs={3} lg={5}>
          <PrimaryButton
            dataTestId={
              ConnectJobDiscoveryFiltersDataTestIds.UPDATE_DISTANCE_FROM_LOCATION_SUBMIT_BUTTON
            }
            size="large"
            color="black"
            onClick={(e) => handleAddressSearch(e, locationInput, selectedDistance)}
            sx={buttonStyles(theme)}
            isLoading={isLoading}
            loadingSpinnerSize={20}
          >
            <Typography alignSelf={'center'}>Search</Typography>
          </PrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const inputStyles = (theme: Theme) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
  },
  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
      height: theme.spacing(2.5),
    },
  },
});

const selectStyles = (theme: Theme) => ({
  width: '100%',
  minWidth: 110,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
});

const buttonStyles = (theme: Theme) => ({
  minWidth: '36px',
  width: '100%',
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius * 0.5,
});

const errorTextStyles = (theme: Theme) => ({
  color: theme.palette.error.main,
  alignSelf: 'center',
});
