import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import LoadingSpinner from '../../../loadingSpinner';
import PendingHellosignFormRow from './PendingHellosignFormRow';
import { ReactComponent as PaperClipIcon } from 'assets/icon-paperclip.svg';
import { showWarning } from 'utils/message';

import HellosignAPI from 'api/hellosignAPI';
import { nimbleTheme } from 'theme';
import { ATSAttachmentsDataTestIds } from 'data-testids/ATS';

export default function PendingHelloSignForms({ application, helloSignTemplateModalOpen }) {
  const [data, setData] = useState({ pending: [], processing: [] });
  const activeRequest = useRef(false);
  const intervalCount = useRef(0);
  const intervalId = useRef(null);
  useEffect(() => {
    if (activeRequest.current) return;
    const fetchData = async () => {
      activeRequest.current = true;
      const responseData = await HellosignAPI.getPendingForms(application.id);
      setData(responseData);
      activeRequest.current = false;
      if (!helloSignTemplateModalOpen && responseData.processing.length) {
        intervalId.current = setInterval(async () => {
          intervalCount.current += 1;
          activeRequest.current = true;
          const responseData = await HellosignAPI.getPendingForms(application.id);
          setData(responseData);
          activeRequest.current = false;
          if (!responseData.processing.length || intervalCount.current > 180) {
            clearInterval(intervalId.current);
          }
        }, 5000);
      }
    };
    fetchData();
    return () => clearInterval(intervalId.current);
  }, [application, helloSignTemplateModalOpen]);

  const handleCancellation = async (signature_request_id) => {
    const dataCopy = { pending: [...data.pending], processing: [...data.processing] };

    const updatedData = data.pending.filter((i) => i.signature_request_id !== signature_request_id);
    // update the local data immediately, but disable the revalidation
    setData((prev) => ({ ...prev, pending: updatedData }));

    try {
      await HellosignAPI.cancelRequest(signature_request_id);
    } catch (error) {
      onError(error);
      // put the old data back
      setData(dataCopy);
    }
  };

  const onError = (error) => {
    if (error.response?.status === 404) {
      // task was already completed or cancelled, so we can swallow the error
      return;
    }

    showWarning(
      'There was a problem canceling this form. Please contact support@hirenimble.com for assistance.'
    );
  };

  return (
    <>
      {data.pending.map((pendingForm) => (
        <PendingHellosignFormRow
          key={pendingForm.signature_request_id}
          pendingForm={pendingForm}
          handleCancellation={handleCancellation}
        />
      ))}
      {data.processing.map((form) => (
        <ItemContainer key={form.id} data-testid={ATSAttachmentsDataTestIds.PREPARING_FORM}>
          <GridContainer>
            <LoadingSpinner
              fontSize={2}
              margin={0}
              additionalStyles={{ display: 'flex' }}
              additionalIconStyles={{ fontSize: '1.5em' }}
            />
            <LightText
              data-testid={ATSAttachmentsDataTestIds.PREPARING_FORM_TEXT}
            >{`${form.title} (Preparing)`}</LightText>
          </GridContainer>
        </ItemContainer>
      ))}
    </>
  );
}

export const ItemContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #a6a7ad;
  box-sizing: border-box;
  border-radius: 5px;

  min-height: 42px;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;
  align-items: center;

  margin-bottom: 6px;
  padding: 0 13px;

  @media print {
    border: 0;
    margin: 0;
    min-height: 0;
    padding: 0;
  }
`;

export const GridContainer = styled.div`
  min-height: 42px;
  width: 100%;

  display: grid;
  grid-template-columns: 18px 1fr;
  grid-gap: 8px;
  align-items: center;
`;

export const AttachmentIcon = styled(PaperClipIcon)`
  height: 18px;
  width: 18px;
`;

export const LightText = styled.p`
  font-weight: 400;
  font-size: 15px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media print {
    font-size: ${nimbleTheme.typography.print.fontSize};
  }
`;
