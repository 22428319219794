import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { captureException } from '@sentry/browser';

import { ResumeForm } from './ResumeForm';
import { CertificationsForm } from './CertificationsForm';
import { TeachingExperienceInput } from './TeachingExperienceInput';

import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';
import { QualificationsFormData } from 'types/connectTypes';
import { ConnectProfileFlowButtonGroup } from '../SharedComponents/ConnectProfileFlowButtonGroup';

import {
  getGatedActionDisplayInfo,
  getInitialUSVisaEligibility,
  NO_FILE_SELECTED_ERROR_MESSAGE,
  updateConnectFirstProfileSetupDateTime,
} from '../utils';
import auth from 'utils/auth';

import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import connectSubscriptionsAPI from 'api/connectSubscriptionsAPI';
import expressInterestAPI from 'features/Connect/api/expressInterestAPI';
import { empty_certification_input } from './constants';
import { useStateParam } from '../../ConnectStateCodeContextProvider';
import { getStateIDFromStateCode } from 'features/Connect/utils/connectUtils';
import {
  ConnectProfileFlowContainer,
  ConnectProfileFlowFormContainer,
  ConnectProfileFlowPaperContainer,
  ConnectProfileFlowTitle,
} from '../styles';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';

export const ResumeAndCertificationsForm = (): React.ReactElement => {
  const user = auth.getUser();
  const stateParam = useStateParam();
  const stateCode = CONNECT_JOBBOARD_STATES[stateParam]?.stateCode;

  const defaultStateID = useMemo(() => {
    return getStateIDFromStateCode(stateCode);
  }, [stateCode]);

  const [userDataIsLoading, setUserDataIsLoading] = useState<boolean>(true);
  const [userFormData, setUserFormData] = useState<QualificationsFormData>({
    hasCertification: true,
    certifications: [empty_certification_input(defaultStateID)],
    resume: '',
    yearsOfTeachingExperience: user.profile.years_of_teaching_experience || '',
    isEligibleToWorkInUS: getInitialUSVisaEligibility(user),
  });

  const [resumeIsUploading, setResumeIsUploading] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [resumeUploadError, setResumeUploadError] = useState<string>('');
  const [isTeachingExperienceError, setIsTeachingExperienceError] = useState<boolean>(false);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const gatedAction = searchParams.get('action')?.toLowerCase();
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();

  const getConnectCertifications = () => {
    return userFormData.certifications.filter((certification) => certification.isHidden === false);
  };

  const removeIncompleteCertifications = () => {
    return userFormData.certifications.filter((certification) => {
      const isHiddenCredential = certification.isHidden;
      const hasValidStateAndSubject = !!certification.state && !!certification.subject?.id;
      return isHiddenCredential || hasValidStateAndSubject;
    });
  };

  const certificationsFormHasErrors = () => {
    let hasErrors = false;
    const requiresCertificationValidation = userFormData.hasCertification;

    if (!requiresCertificationValidation) {
      hasErrors = false;
    } else {
      getConnectCertifications().forEach((certification) => {
        const formCertificationIndex = userFormData.certifications.findIndex(
          (formCertification) => formCertification.client_id === certification.client_id
        );

        const hasSubjectError = !certification?.subject?.id;
        const hasStateError = !certification?.state;

        userFormData.certifications[formCertificationIndex].errors = {
          hasSubjectError,
          hasStateError,
        };

        if (hasSubjectError || hasStateError) {
          hasErrors = true;
        }
      });
      setUserFormData({ ...userFormData, certifications: [...userFormData.certifications] });
    }
    return hasErrors;
  };

  const handleYearsOfExperienceChange = (optionValue: string) => {
    if (isTeachingExperienceError) {
      setIsTeachingExperienceError(false);
    }

    setUserFormData({ ...userFormData, yearsOfTeachingExperience: optionValue });
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'hasCertification') {
      if (value === 'true' && getConnectCertifications().length === 0) {
        setUserFormData({
          ...userFormData,
          hasCertification: true,
          certifications: [
            ...userFormData.certifications,
            empty_certification_input(defaultStateID),
          ],
        });
        return;
      } else if (value === 'false') {
        const updatedCertifications = getConnectCertifications().map((certification) => {
          const errors = {
            hasSubjectError: false,
            hasStateError: false,
          };
          certification.errors = errors;
          return certification;
        });
        setUserFormData({
          ...userFormData,
          hasCertification: true,
          certifications: [...updatedCertifications],
        });
      }

      setUserFormData({ ...userFormData, hasCertification: value === 'true' });
      return;
    }

    setUserFormData({ ...userFormData, [name]: value });
  };

  const isFormValid = () => {
    let isFormValid = true;
    const { resume, yearsOfTeachingExperience } = userFormData;

    if (!resume && pageDisplayInformation.requiredFields.includes('resume')) {
      setResumeUploadError(NO_FILE_SELECTED_ERROR_MESSAGE);
      isFormValid = false;
    }
    if (certificationsFormHasErrors()) {
      isFormValid = false;
    }
    if (
      !yearsOfTeachingExperience &&
      pageDisplayInformation.requiredFields.includes('yearsOfTeachingExperience')
    ) {
      setIsTeachingExperienceError(true);
      isFormValid = false;
    }
    return isFormValid;
  };

  const fetchUserData = async () => {
    try {
      setUserDataIsLoading(true);
      const userData = await auth.getUserAsync();
      const { resume, credentials } = await expressInterestAPI.fetchUserProfileAndCredentials(
        user.id
      );

      const consolidatedUserData = {
        ...userFormData,
        isEligibleToWorkInUS: getInitialUSVisaEligibility(userData),
        yearsOfTeachingExperience:
          userData.profile.years_of_teaching_experience == null
            ? ''
            : userData.profile.years_of_teaching_experience,
        certifications: credentials,
        resume,
      };

      setUserFormData(consolidatedUserData);
    } catch (error) {
      captureException(error);
    } finally {
      setUserDataIsLoading(false);
    }
  };

  const handleSaveAndContinue = async () => {
    if (isFormValid()) {
      try {
        setIsFormSubmitting(true);
        let certificationsToSave = userFormData.certifications;
        if (!userFormData.hasCertification) {
          certificationsToSave = userFormData.certifications.filter(
            (certification) => certification.isHidden
          );
        } else {
          certificationsToSave = removeIncompleteCertifications();
        }

        const updatedUserData = {
          userId: user.id,
          credentials: certificationsToSave,
          yearsOfTeachingExperience: userFormData.yearsOfTeachingExperience,
          isEligibleToWorkInUS: userFormData.isEligibleToWorkInUS,
        };

        await expressInterestAPI.updateUserCredentialsAndProfile(updatedUserData);
        if (gatedAction === ConnectGatedActions.ALERT) {
          await connectSubscriptionsAPI.createSubscription();
        }
        setIsFormSubmitting(false);
        history.push(pageDisplayInformation.continueToUrl);
      } catch (error) {
        captureException(error);
        setIsFormSubmitting(false);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userFormData.hasCertification && getConnectCertifications().length === 0) {
      setUserFormData({
        ...userFormData,
        certifications: [...userFormData.certifications, empty_certification_input(defaultStateID)],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFormData.certifications, userFormData.hasCertification]);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user);
  }, [user]);

  return (
    <ConnectProfileFlowContainer>
      <HorizontalLinearStepper
        activeStep={pageDisplayInformation.step}
        steps={flowDisplayInformation.stepper.steps}
        mobileSteps={flowDisplayInformation.stepper.mobileSteps}
      />
      <ConnectProfileFlowPaperContainer elevation={3}>
        <ConnectProfileFlowFormContainer component="form">
          <ConnectProfileFlowTitle variant="h1">
            {pageDisplayInformation.headerText}
          </ConnectProfileFlowTitle>
          <Stack spacing={1.75} px={{ xs: 2, sm: 7 }} py={{ xs: 2, sm: 1.25 }}>
            <ResumeForm
              userFormData={userFormData}
              setUserFormData={setUserFormData}
              userDataIsLoading={userDataIsLoading}
              resumeIsUploading={resumeIsUploading}
              setResumeIsUploading={setResumeIsUploading}
              resumeUploadError={resumeUploadError}
              setResumeUploadError={setResumeUploadError}
              pageDisplayInformation={pageDisplayInformation}
            />
            <TeachingExperienceInput
              isUserDataIsLoading={userDataIsLoading}
              userFormData={userFormData}
              handleYearsOfExperienceChange={handleYearsOfExperienceChange}
              isTeachingExperienceError={isTeachingExperienceError}
              pageDisplayInformation={pageDisplayInformation}
            />
            <CertificationsForm
              isUserDataLoading={userDataIsLoading}
              handleFormChange={handleFormChange}
              userFormData={userFormData}
              setUserFormData={setUserFormData}
              certificationsFormHasErrors={certificationsFormHasErrors}
              getConnectCertifications={getConnectCertifications}
              isFormSubmitting={isFormSubmitting}
              pageDisplayInformation={pageDisplayInformation}
              defaultStateID={defaultStateID}
            />
          </Stack>
        </ConnectProfileFlowFormContainer>
      </ConnectProfileFlowPaperContainer>
      <ConnectProfileFlowButtonGroup
        dataTestId={''}
        primaryButton={{
          dataTestId: '',
          primaryButtonLabel: pageDisplayInformation?.primaryButtonLabel,
          primaryAction: handleSaveAndContinue,
          disabled: false,
          isLoading: isFormSubmitting,
        }}
        secondaryButton={{
          dataTestId: '',
          secondaryButtonLabel: 'Back',
          secondaryAction: () => history.push(pageDisplayInformation.backUrl),
        }}
        hasError={false}
      />
    </ConnectProfileFlowContainer>
  );
};

export const QuestionLabel = styled(InputLabel)(() => ({
  textWrap: 'wrap',
}));
