import { Box, Button, Stack, styled, Typography, useTheme } from '@mui/material';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { FileUploadErrorModalProps } from './types';
import { ErrorOutline } from '@mui/icons-material';
import { Modal } from 'sharedComponents/Modal';
import { Alert } from 'sharedComponents/Alert';

export const FileUploadErrorModal: React.FC<FileUploadErrorModalProps> = ({
  isOpen,
  onClose,
  onClick,
  errorMessage,
}) => {
  const theme = useTheme();

  const ModalTitle = () => {
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <ErrorOutline sx={{ color: theme.palette.error.main }} />
        <Title variant="h2">File update was not successful</Title>
      </Stack>
    );
  };

  return (
    <Modal
      sx={modalStyles(theme)}
      open={isOpen}
      onClose={onClose}
      title={<ModalTitle />}
      borderRadius={theme.shape.borderRadius * 2}
      hasBoxShadow={true}
      isTitleCentered={true}
      ctaButtons={
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
          <CancelButton size="extraLarge" onClick={onClose}>
            Cancel
          </CancelButton>
          <PrimaryButton size="extraLarge" onClick={onClick} color="black">
            Try Again
          </PrimaryButton>
        </Box>
      }
    >
      <Alert type="error" icon={false} sx={alertStyles(theme)}>
        {errorMessage}
      </Alert>
    </Modal>
  );
};

const modalStyles = (theme) => ({
  justifyContent: 'center',
  width: '100%',
  borderRadius: theme.shape.borderRadius * 2,
});

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(2),
  border: '1px solid var(--secondary-outlinedBorder, rgba(58, 58, 58, 0.50))',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: theme.palette.border.secondary,
    boxShadow: 'none',
  },
}));

const alertStyles = (theme) => ({
  color: theme.palette.error.dark,
  width: '100%',
  fontSize: theme.typography.heading7,
  textAlign: 'center',
  justifyContent: 'center',
});
