import { uniqueId } from 'lodash';
import { CertificationInput } from 'types/connectTypes';

export const CERTIFICATION_FORM_INPUT_TYPE = {
  STATE: 'state',
  SUBJECT: 'subject',
};

export const empty_certification_input = (stateID = null): CertificationInput => {
  return {
    // client_id is used to uniquely identify each certification input during CRUD operations
    client_id: uniqueId(),
    state: stateID,
    isHidden: false,
    errors: { hasSubjectError: false, hasStateError: false },
    subject: null,
  };
};

export enum YearsOfTeachingExperience {
  ONE_TO_TWO_YEARS = 'one_to_two_years',
  THREE_TO_FIVE_YEARS = 'three_to_five_years',
  SIX_TO_NINE_YEARS = 'six_to_nine_years',
  TEN_PLUS_YEARS = 'ten_plus_years',
  SUBSTITUTE_OR_PART_TIME_OR_AIDE = 'substitute_part_time_aide',
  NO_TEACHING_EXPERIENCE = 'no_teaching_experience',
}

export const yearsOfExperienceOptions = [
  { value: YearsOfTeachingExperience.ONE_TO_TWO_YEARS, label: '1-2 years' },
  { value: YearsOfTeachingExperience.THREE_TO_FIVE_YEARS, label: '3-5 years' },
  { value: YearsOfTeachingExperience.SIX_TO_NINE_YEARS, label: '6-9 years' },
  { value: YearsOfTeachingExperience.TEN_PLUS_YEARS, label: '10+ years' },
  {
    value: YearsOfTeachingExperience.SUBSTITUTE_OR_PART_TIME_OR_AIDE,
    label: 'Sub/part-time/aide experience',
  },
  { value: YearsOfTeachingExperience.NO_TEACHING_EXPERIENCE, label: 'No teaching experience' },
];
