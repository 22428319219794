import { styled, Theme, Typography, useTheme } from '@mui/material';
import { Grid, GridItem } from 'sharedComponents/Grid';
import { QuestionLabel } from './ResumeAndCertificationsForm';
import { BasicSelect } from 'sharedComponents/Select';
import { yearsOfExperienceOptions } from './constants';

import { TeachingExperienceInputProps } from './types';

const INPUT_ID = 'years-of-teaching-experience';

export const TeachingExperienceInput: React.FC<TeachingExperienceInputProps> = ({
  isUserDataIsLoading,
  userFormData,
  handleYearsOfExperienceChange,
  isTeachingExperienceError,
  pageDisplayInformation,
}) => {
  const theme = useTheme();
  if (isUserDataIsLoading) {
    return null;
  }

  return (
    <Grid>
      <GridItem xs={12} sm={12}>
        <QuestionLabel
          htmlFor={INPUT_ID}
          required={pageDisplayInformation.requiredFields.includes('yearsOfTeachingExperience')}
          aria-required
        >
          Years of full-time teaching experience
        </QuestionLabel>
        <BasicSelect
          id={INPUT_ID}
          displayName="Select years of full-time teaching experience"
          formControlProps={{ fullWidth: true }}
          handleChange={handleYearsOfExperienceChange}
          options={yearsOfExperienceOptions}
          size="small"
          defaultValue={userFormData.yearsOfTeachingExperience}
          showError={isTeachingExperienceError}
          sx={SelectStyles(theme)}
        />
        {isTeachingExperienceError && <ErrorText>Please select an option</ErrorText>}
      </GridItem>
    </Grid>
  );
};

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
}));

const SelectStyles = (theme: Theme) => ({
  width: '100%',
  minHeight: '44px',

  '& .MuiOutlinedInput-root': {
    '& legend': {
      width: 0,
    },
    borderRadius: theme.shape.borderRadius * 0.75,
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
  },
  '& .MuiInputLabel-root': {
    position: 'absolute',
    transform: 'translate(12px, 12px) scale(1)',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
    },
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  '& .MuiInputLabel-shrink': {
    display: 'none',
  },
  '& .MuiInputBase-root': {
    minHeight: '44px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(0.2),
    overflow: 'hidden',

    '&:focus-within .MuiInputBase-input': {
      width: '100%',
    },
  },
});
