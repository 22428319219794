import { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { nimbleTheme } from 'theme';

import BasicInputs from '../components/Application/BasicInputs';
import ApplicationProgressBar from '../components/Application/ApplicationProgressBar';
import Header from '../features/ProfilePreference/components/Header';
import Subfooter from '../components/subfooter_poweredby';
import LoadingSpinner from '../components/loadingSpinner';
import styled from 'styled-components';
import auth from '../utils/auth.js';
import { noVisibleAttachments } from '../utils/roleutils';
import { showTotalFailure, showErrorNotification, myColor } from '../utils/message';
import { CandidateErrors } from 'sharedComponents/Error/constants';
import { bytesToSize, fileNameShortener, checkInternal } from '../utils/util';
import { parseMomentFromString } from 'utils/util';
import { getOrSetPreferenceFlag } from 'utils/preferenceConfig';

import usersAPI from 'api/usersAPI';
import { Logo } from 'ui-kit';
import { withRouter } from 'react-router-dom';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage';
import { ATSCandidateBasicInfoDataTestIds } from '../data-testids/ATS';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';

import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Alert } from 'sharedComponents/Alert';
import { Modal } from 'sharedComponents/Modal';
import { Box, Stack, Typography } from '@mui/material';
import { RequiredAsterix } from 'features/FlatFileTransferDashboard/components/FlatFileTransferInputs';
import ErrorText from 'components/errortext';

const RESUME_TIMESTAMP_LENGTH = 14;
class ApplicantProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.getUser = auth.getUser();
    const combinedPreferenceAndProfile = false;
    let editProfileMode = this.props.match.params.id === 'edit-profile' ? true : false;
    let editApplicationMode = window.location.search.indexOf('ea') !== -1 ? true : false;

    this.state = {
      showSpinner: false,
      showFileUploadSpinner: false,
      job_id: this.props.match.params.id,
      combinedPreferenceAndProfile,
      editProfileMode,
      editApplicationMode,
      isUploadErrorModalOpen: false,
      displayUploadSuccessMessage: false,
      role: {
        requiredapplicationattachment_set: [],
        district: {},
      },
      application: {
        source: null,
        references: [],
        explanation: '',
        custom_answers: [],
      },
      resume: {},
      uploadErrorMessages: {
        resume: '',
      },
      user: {
        profile: {},
      },
      skipDocumentationPage: false,
      resumeSectionVisible: true,
      resumeSectionRequired: false,
      // This will determine whether or not to make an event on the backend
      user_made_edits: false,
      ssn_loading_spinner: false,
      birthdayErrorOnSubmit: null,
      eeoErrorOnSubmit: null,
    };
    this.checkInternal = checkInternal.bind(this);
    this.noVisibleAttachments = noVisibleAttachments.bind(this);
  }

  async componentDidMount() {
    const combinedPreferenceAndProfileFlag = await getOrSetPreferenceFlag();
    const combinedPreferenceAndProfile =
      this.props.match.params.id === 'edit-profile' && combinedPreferenceAndProfileFlag;
    this.setState({ combinedPreferenceAndProfile });
    // If coming back from Step 2, reload to get any changed data
    if (window.location.search.indexOf('back') !== -1) {
      // Remove the query string, add the editing query string if necessary, and reload the page
      const editApplicationString = window.location.search.indexOf('ea') !== -1 ? '?ea=t' : '';
      const location = window.location.href.split('?')[0] + editApplicationString;
      window.location.href = location;
    }
    document.body.classList.add('applicant-pages');
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // If in the flow for applying for a role (as opposed to just editing their profile)
    if (!this.state.editProfileMode) {
      axios
        .get(`/api/role/${this.props.match.params.id}/`)
        .then((r) => {
          if (!this.ignoreLastFetch) {
            this.setState(
              {
                role: r.data,
                district: r.data.district.id,
              },
              () => {
                this.setInternalCandidate();
                this.determineResumeSection();
                this.checkIfWillSkipDocumentationPage();
              }
            );
          }
        })
        .catch((error) => showTotalFailure(error));
    }

    usersAPI.getCandidateInfo(auth.getUser().id).then((user) => {
      this.setState({
        user,
      });
      if (!this.state.editProfileMode && this.needsApplication(user)) {
        this.createApplication();
      }
    });

    this.setInternalCandidate();
  }

  needsApplication = (user) => {
    return !this.hasExistingApplication(user);
  };

  hasExistingApplication = (user) => {
    // see if an application has already been created for this role
    const job_id = Number(this.state.job_id);
    return user.roles_applied_to.includes(job_id);
  };

  createApplication = () => {
    axios.post(`/api/role/${this.state.job_id}/apply_for_role/`, this.state.application);
  };

  setInternalCandidate = () => {
    if (
      this.checkInternal(this.getUser, this.state.district) === true &&
      this.getUser.profile.district?.id !== this.state.district
    ) {
      // set district (which is used to determine if candidate is internal) if the candidate
      // entered the password on external jobslist.
      this.handleProfileChange({
        target: {
          name: 'district',
          value: { id: this.state.district },
        },
      });
    }
  };

  checkIfWillSkipDocumentationPage = () => {
    let skipDocumentationPage = false;
    // If there are no addtl. questions or attachments we're going to skip the documentation page (page 3)
    // Addition 3/1/2019: or if it's an internal candidate and there are no questions/attachments required
    // for internal candidates
    if (this.noVisibleAttachments(this.state.role)) {
      skipDocumentationPage = true;
    }
    this.setState({ skipDocumentationPage });
  };

  startSpinner = () => {
    this.setState({ showSpinner: true });
  };

  stopSpinner = () => {
    this.setState({ showSpinner: false });
  };

  determineIfSSNRequired = () => {
    if (this.state.editProfileMode) {
      // if in edit profile mode, check the "requires_ssn" property
      // which will be true if any district the user has applied to requires SSN.
      return this.state.user.requires_ssn;
    } else {
      return this.state.role.district.ssn_required;
    }
  };

  determineIfBirthdayRequired = () => {
    let birthdayRequired;
    if (this.state.editProfileMode) {
      // if in edit profile mode, check the "requires_birthday" property
      // which will be true if any district the user has applied to requires a Birthday.
      return this.state.user.requires_birthday;
    } else {
      birthdayRequired = this.state.role.district.birthday_required;
    }
    return birthdayRequired ?? false;
  };

  getSSN = () => {
    this.setState({ ssn_loading_spinner: true });
    axios
      .get(`/api/user/${this.state.user.id}/get_ssn/`)
      .then((r) => {
        let user = this.state.user;
        user.profile.ssn = r.data.ssn;
        this.setState({
          user,
          ssn_loading_spinner: false,
        });
      })
      .catch(() => {
        this.setState({ ssn_loading_spinner: false });
        showErrorNotification(CandidateErrors.SSN_RETRIEVAL_FAILED);
      });
  };

  /*
   * Autosave runs when user makes change to form and bypasses frontend
   * error checks so anything the user has updated is saved
   */
  handleSubmit = async (e, autosave) => {
    let user_data = Object.assign({}, this.state.user);

    // if autosave and updated fields are not valid, don't attempt to save
    if (autosave) {
      try {
        this.validateBirthday();
        this.validateEEOQuestions();
      } catch (error) {
        return;
      }
    }

    if (!autosave) {
      try {
        this.validateBirthday();
      } catch (error) {
        this.setState(
          {
            birthdayErrorOnSubmit: error.message,
            showSpinner: false,
          },
          this.scrollToBirthdayInput
        );
        return;
      }

      try {
        this.validateEEOQuestions();
      } catch (error) {
        this.setState(
          {
            eeoErrorOnSubmit: error.message,
            showSpinner: false,
          },
          this.scrollToEEOInputs
        );
        return;
      }

      this.startSpinner();

      if (
        this.state.user_made_edits &&
        (this.state.editProfileMode || this.state.editApplicationMode)
      ) {
        user_data['user_edited_profile'] = true;
      }
    }

    user_data.email = user_data.username;

    const { resume, uploadErrorMessages } = this.state;
    if (!autosave) {
      // If user didn't submit resume, display messages here
      // If user didn't add a resume but has before, that's OK
      if (!resume.size && !this.state.user.profile.resume && this.state.resumeSectionRequired) {
        uploadErrorMessages.resume = 'Please upload a resume.';
        this.setState({ uploadErrorMessages, showSpinner: false });
        return;
      } else {
        uploadErrorMessages.resume = '';
        this.setState({ uploadErrorMessages });
      }
    }

    // update user data
    // HACK: REMOVE THE NESTED FIELDS SO THEY'RE NOT PATCHED INCORRECTLY.
    // THIS IS DUMB BUT EASIER THAN SETTING THE CARDS LIKE WE DO IN application.js
    // Remove the nested items user won't set on this page
    delete user_data['experiences'];
    delete user_data['education'];
    delete user_data['credentials'];
    delete user_data['languages'];
    delete user_data['additionalReferences'];
    delete user_data['linked_users'];
    try {
      await axios.patch(`/api/user/${this.getUser.id}/`, user_data);
    } catch (error) {
      let errorMessage;
      let response = error.response;
      if (response && response.data && response.data.credential) {
        errorMessage = CandidateErrors.APPLICATION_CREDENTIALS;
      } else if (response && response.data && response.data.languageSkill) {
        errorMessage = CandidateErrors.APPLICATION_LANGUAGES;
      } else {
        errorMessage = CandidateErrors.GENERAL;
      }
      this.stopSpinner();
      showErrorNotification(errorMessage);
      return;
    }

    // Upload resume
    if (resume.size) {
      const fileData = new FormData();
      fileData.append('file', resume, resume.name);
      try {
        await axios.post('/api/uploadresume/', fileData);
        this.setState({ resume: fileData, displayUploadSuccessMessage: true });
      } catch (error) {
        this.stopSpinner();
        this.setState({
          uploadErrorMessages: { resume: error.response.data.error },
          isUploadErrorModalOpen: true,
          displayUploadSuccessMessage: false,
        });
        return;
      }
    }

    // If not autosaving, go to next page or back to candidate dashboard
    if (!autosave) {
      this.props.history.push(`/application/${this.state.job_id}/${window.location.search}`);
    }
  };
  /* When autosaving, delay 20 seconds before sending another POST */
  debouncedHandleSubmit = _.debounce(() => {
    this.handleSubmit(null, true);
  }, 20000);

  handleChange = (event) => {
    let new_value = null;
    if (event.target.type === 'radio') {
      if (typeof event.target.value === 'string') {
        if (event.target.value === 'false') {
          new_value = false;
        } else if (event.target.value === 'true') {
          new_value = true;
        }
      }
    } else {
      new_value = event.target.value;
    }
    this.setState(
      {
        [event.target.name]: new_value,
        user_made_edits: true,
      },
      // Autosave
      this.debouncedHandleSubmit
    );
  };

  updateUserProfile = (data) => {
    this.setState(
      (prevState) => ({
        user: {
          ...prevState.user,
          profile: { ...prevState.user.profile, ...data },
        },
        user_made_edits: true,
        eeoErrorOnSubmit: null,
      }),
      () => {
        // Autosave
        this.debouncedHandleSubmit();
      }
    );
  };

  handleUserChange = (event) => {
    let newUserCopy = Object.assign({}, this.state.user);
    newUserCopy[event.target.name] = event.target.value;
    this.setState({ user: newUserCopy, user_made_edits: true }, () => {
      // Autosave
      this.debouncedHandleSubmit();
    });
  };

  handleProfileChange = (event) => {
    let new_value = event.target.value;
    if (event.target.type === 'radio') {
      if (typeof event.target.value === 'string') {
        if (event.target.value === 'false') {
          new_value = false;
        } else if (event.target.value === 'true') {
          new_value = true;
        }
      }
    } else if (event.target.type === 'checkbox') {
      new_value = event.target.checked;
    }

    // if entering ssn, add a hyphen
    if (event.target.name === 'ssn') {
      new_value = this.hyphenate(new_value);
    }

    this.updateUserProfile({ [event.target.name]: new_value });
  };

  handleMultiSelectChange = (values, fieldName) => {
    // values parameter can be an array of objects, or one object (not in an array)
    if (Array.isArray(values)) {
      values = values.map((value) => value.value);
    } else if (values) {
      values = values.value;
    } else {
      values = '';
    }

    this.updateUserProfile({ [fieldName]: values });
  };

  handleBirthdayChange = (isoDateString) => {
    this.updateUserProfile({ birthday: isoDateString });
  };

  // ----- Begin resume functions ------- //
  uploadResume = async (event) => {
    this.setState({ showFileUploadSpinner: true });

    const fileData = event.target.files[0];
    const uploadErrorMessages = Object.assign({}, this.state.uploadErrorMessages);

    /* ensure no empty files are uploaded */
    if (typeof fileData === 'undefined' || fileData.size === 0) {
      uploadErrorMessages.resume = 'Upload is empty, please try again';
      this.setState({
        uploadErrorMessages,
        showFileUploadSpinner: false,
        isUploadErrorModalOpen: true,
        displayUploadSuccessMessage: false,
      });
      return;
    }

    /* no reasonable file upload should be greater than 5MB in size */
    if (fileData.size > 5242880) {
      uploadErrorMessages.resume = 'Resume must be smaller than 5MB';
      this.setState({
        uploadErrorMessages,
        showFileUploadSpinner: false,
        isUploadErrorModalOpen: true,
        displayUploadSuccessMessage: false,
      });
      return;
    }

    /**
     * ~ 30 characters are prepended to file name (/resumes/None/(timestamp)), and max_length
     * is 255, so ensure input file name is no more than 215 characters, to be safe.
     */

    if (fileData.name.length > 215) {
      uploadErrorMessages.resume =
        "Please ensure your resume's file name is fewer than 215 characters";
      this.setState({
        uploadErrorMessages,
        showFileUploadSpinner: false,
        isUploadErrorModalOpen: true,
        displayUploadSuccessMessage: false,
      });
      return;
    }

    /* if this point is reached, resume is valid, so remove error messages if they exist */
    uploadErrorMessages.resume = '';
    this.setState({
      uploadErrorMessages,
    });

    try {
      const formData = new FormData();
      formData.append('file', fileData, fileData.name);
      await axios.post('/api/uploadresume/', formData);
      this.setState({
        resume: fileData,
        isUploadErrorModalOpen: false,
        displayUploadSuccessMessage: true,
      });
    } catch (error) {
      this.stopSpinner();
      this.setState({
        uploadErrorMessages: { resume: error.response.data.error },
        isUploadErrorModalOpen: true,
        displayUploadSuccessMessage: false,
      });
    } finally {
      this.setState({ showFileUploadSpinner: false });
    }
  };

  clickResumeInput = () => {
    if (this.resumeInput) {
      this.resumeInput.value = '';
    }

    this.resumeInput.click();
  };

  getResumeNameWithoutTimestamp = () => {
    return fileNameShortener(this.state.user.profile.resume).substring(RESUME_TIMESTAMP_LENGTH);
  };

  resume = () => {
    const resumeName =
      this.state.resume?.name ||
      (this.state.user?.profile?.resume && this.getResumeNameWithoutTimestamp()) ||
      '';

    if (resumeName) {
      return (
        <div>
          <div className="truncate bold-text">{resumeName}</div>
          {this.state.displayUploadSuccessMessage && (
            <div className="flex-text">
              <Typography>Uploaded Successfully</Typography>
              <CheckIcon />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Select a file to upload</span>
            <span>
              {this.state.resumeSectionRequired && (
                <RequiredAsterix>&nbsp; * &nbsp;</RequiredAsterix>
              )}
            </span>
          </div>
          <div>(.pdf or .docx)</div>
        </div>
      );
    }
  };

  determineResumeSection = () => {
    /*
     ** Determines whether the resume section should show and be required during submission
     */
    let result = { visible: true, required: true };

    const resumeRoleSection = this.state.role.role_sections.find((rs) => rs.section === 7);
    // The user must be internal AND the role must be specified as one which has a distinction
    if (
      this.state.role.internal_requirements_specified &&
      this.checkInternal(this.getUser, this.state.district)
    ) {
      result.visible = resumeRoleSection.visible_internal;
      result.required = resumeRoleSection.required_internal;
    } else {
      result.visible = resumeRoleSection.visible;
      result.required = resumeRoleSection.required;
    }

    this.setState({
      resumeSectionVisible: result.visible,
      resumeSectionRequired: result.required,
    });
  };
  // ----- End resume functions ------- //

  hyphenate = (new_ssn) => {
    let current_ssn_length = this.state.user.profile.ssn.length;
    // if current length is 4 and new length is 3, the user is backspacing,
    // so don't add a hyphen
    if (
      (new_ssn.length === 3 && current_ssn_length !== 4) ||
      (new_ssn.length === 6 && current_ssn_length !== 7)
    ) {
      new_ssn += '-';
    }
    return new_ssn;
  };

  validateBirthday = () => {
    const birthdayRequired = this.determineIfBirthdayRequired();
    if (birthdayRequired) {
      if (
        !this.state.user?.profile?.birthday ||
        !parseMomentFromString(this.state.user.profile.birthday).isValid()
      ) {
        throw new Error('Please enter a valid date in mm/dd/yyyy format');
      }
    }
  };

  validateEEOQuestions = () => {
    if (
      [null, undefined].includes(this.state.user?.profile?.gender) ||
      [null, undefined].includes(this.state.user?.profile?.race_ethnicity)
    ) {
      throw new Error('Please select an option from both the Gender and Ethnicity dropdowns');
    }
  };

  handleSSNthenSubmit = (e) => {
    e.preventDefault();
    // had to put this in front of handle submit so we can prevent the user
    // from moving forward with ssn errors

    let ssn_required = this.determineIfSSNRequired();
    let ssn = this.state.user.profile.ssn;
    // don't send if ssn isn't required or if it's already tokenized
    if (!ssn_required || ssn.indexOf('tok_') !== -1) {
      this.handleSubmit(e, false);
    } else {
      this.startSpinner();
      let data = { ssn };
      axios
        .post(`/api/user/${this.getUser.id}/create_ssn_token/`, data)
        .then(() => this.handleSubmit(e, false))
        .catch((err) => {
          const errorMessageStyles = { ...myColor, background: '#CC0000' };

          this.stopSpinner();
          if (err.response && err.response.data.detail === 'SSN already exists') {
            showErrorNotification(CandidateErrors.SSN_ALREADY_EXISTS, 20000, errorMessageStyles);
          } else {
            showErrorNotification(CandidateErrors.GENERAL, 20000, errorMessageStyles);
          }
        });
    }
  };

  scrollToElementById = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ alignToTop: true, behavior: 'smooth' });
    }
  };

  scrollToBirthdayInput = () => {
    this.scrollToElementById('birthday-input');
  };

  scrollToEEOInputs = () => {
    this.scrollToElementById('eeo-inputs');
  };

  renderLogo = () => {
    if (this.state.job_id === 'edit-profile') {
      return (
        <div className="logo-container-inner">
          <img
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = 'none';
            }}
            src="/jobboard_logos/nimble-logo-round.png"
            alt="logo"
            className="nimble-logo"
          />
        </div>
      );
    } else if (this.state.role.id && this.state.role.jobboards[0]?.logo) {
      return <Logo src={this.state.role.jobboards[0].logo} maxHeight={54} />;
    }
  };

  handleCloseUploadErrorModal = () => {
    this.setState({ isUploadErrorModalOpen: false });
  };

  render() {
    const { isUploadErrorModalOpen, showFileUploadSpinner } = this.state;
    return (
      <div>
        {this.state.combinedPreferenceAndProfile ? (
          <Header step={2} />
        ) : (
          <div className="header-section">
            <div className="logo-container-outer">{this.renderLogo()}</div>
            <h2 data-testid={ATSCandidateBasicInfoDataTestIds.COMPLETE_PROFILE_TITLE}>
              Complete your profile
            </h2>
            <div className="header-section-long-text">
              You'll only have to complete this information once, and it will be saved for each job
              you apply to! Be sure to complete it thoroughly, to help ensure you're selected for
              the right opportunities. All fields marked with an asterisk are required.
            </div>
            <div className="ie-show">
              <strong>
                NOTE: We suggest using Google Chrome to submit your application. If you don't
                already have it, you can download Chrome{' '}
                <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                  here
                </a>
                .
              </strong>
            </div>
            {(this.state.role.id || this.state.editProfileMode) && (
              <ApplicationProgressBar
                step={1}
                editProfileMode={this.state.editProfileMode}
                skipDocumentationPage={this.state.skipDocumentationPage}
              />
            )}
          </div>
        )}

        <div className="flex application-container">
          <form
            className="flex-3"
            encType="multipart/form-data"
            onSubmit={this.handleSSNthenSubmit}
          >
            {this.state.role.title && (
              <div className="application-section">
                <h3 id="role-title" className="application-role-title">
                  {/* {this.state.role.title} */}
                </h3>
              </div>
            )}
            <div className="application-section basic-inputs-container">
              <h4 id="basic" className="section-title">
                Basic Info
              </h4>
              <p>Asterisk (*) indicates a required field.</p>
              <BasicInputs
                user={this.state.user}
                handleChange={this.handleChange}
                handleUserChange={this.handleUserChange}
                handleProfileChange={this.handleProfileChange}
                handleMultiSelectChange={this.handleMultiSelectChange}
                getSSN={this.getSSN}
                ssn_loading_spinner={this.state.ssn_loading_spinner}
                determineIfSSNRequired={this.determineIfSSNRequired}
                editProfileMode={this.state.editProfileMode}
                isBirthdayRequired={this.determineIfBirthdayRequired()}
                handleBirthdayChange={this.handleBirthdayChange}
                birthdayErrorOnSubmit={this.state.birthdayErrorOnSubmit}
                eeoErrorOnSubmit={this.state.eeoErrorOnSubmit}
              />
            </div>
            {this.state.resumeSectionVisible && (
              <div className="application-section">
                <h4 id="resume" className="section-title">
                  Resume
                </h4>
                <div>
                  <div
                    className="file-upload-container"
                    style={{ border: this.state.uploadErrorMessages.resume ? 'solid red' : 'none' }}
                  >
                    <div className="upload-flex-container">
                      <PrimaryButton
                        onClick={this.clickResumeInput}
                        sx={
                          this.state.user.profile.resume
                            ? secondaryButtonStyles
                            : primaryButtonStyles
                        }
                        isLoading={showFileUploadSpinner}
                        loadingSpinnerSize={20}
                      >
                        {this.state.user.profile.resume ? 'Update' : 'Select'}
                      </PrimaryButton>
                      <div className="file-upload-col-2">
                        <div>{this.resume()}</div>
                      </div>
                      {this.state.resume.name && <div>{bytesToSize(this.state.resume.size)}</div>}
                    </div>
                  </div>
                  <input
                    name="resume"
                    id="upload-file-input"
                    ref={(input) => (this.resumeInput = input)}
                    type="file"
                    accept=".docx,.pdf"
                    style={{ height: '0px', overflow: 'hidden', marginTop: '-0.4rem' }}
                    onChange={this.uploadResume}
                    data-testid={ATSCandidateBasicInfoDataTestIds.UPLOAD_RESUME_INPUT}
                  />
                </div>
                <div className="upload-error-text">
                  <ErrorText message={this.state.uploadErrorMessages.resume} />
                </div>
                <UploadFileHelperMessage />
              </div>
            )}
            {this.state.combinedPreferenceAndProfile ? (
              <ButtonContainer>
                <PreviousStepButton
                  id="submit"
                  className={`submit btn-lrg`}
                  type="submit"
                  value={'Previous Step'}
                  onClick={() => this.props.history.push(`/preferences`)}
                  disabled={this.state.showSpinner}
                />
                <input
                  id="submit"
                  className={`submit btn-lrg`}
                  type="submit"
                  value="Save & next step"
                  data-testid={ATSCandidateBasicInfoDataTestIds.SAVE_AND_NEXT_BUTTON}
                  disabled={this.state.showSpinner}
                  onClick={() => this.setState({ submitted: true })}
                />
              </ButtonContainer>
            ) : (
              <div className={'application-section bottom-section step-1'}>
                <div className={`bottom-section-content`}>
                  <div className="">
                    <input
                      id="submit"
                      className={`submit mt2 btn-lrg`}
                      type="submit"
                      value="Save & next step"
                      data-testid={ATSCandidateBasicInfoDataTestIds.SAVE_AND_NEXT_BUTTON}
                      disabled={this.state.showSpinner}
                      onClick={() => this.setState({ submitted: true })}
                    />

                    {this.state.showSpinner && (
                      <LoadingSpinner
                        margin={0}
                        fontSize={2}
                        message="This may take a few seconds."
                        additionalClassname="bottom"
                      />
                    )}
                  </div>
                </div>
                <Subfooter />
              </div>
            )}
          </form>
        </div>
        <UploadErrorModal
          isOpen={isUploadErrorModalOpen}
          onClose={this.handleCloseUploadErrorModal}
          onClick={this.clickResumeInput}
          errorMessage={this.state.uploadErrorMessages.resume}
        />
      </div>
    );
  }
}

export default withRouter(ApplicantProfileContainer);

class UploadErrorModal extends Component {
  render() {
    const { isOpen, onClose, onClick, errorMessage } = this.props;

    const Title = () => {
      return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <ErrorOutlineIcon sx={{ color: nimbleTheme.palette.error.main }} />
          <Typography sx={errorModalTitleStyles}>File update was not successful</Typography>
        </Stack>
      );
    };

    return (
      <Modal
        sx={errorModalStyles}
        open={isOpen}
        onClose={onClose}
        title={<Title />}
        borderRadius="16px"
        hasBoxShadow={true}
        isTitleCentered={true}
        ctaButtons={
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '32px' }}>
            <SecondaryButton onClick={onClose} sx={errorModalSecondaryButtonStyles}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={onClick} sx={errorModalPrimaryButtonStyles}>
              Try Again
            </PrimaryButton>
          </Box>
        }
      >
        <Alert type="error" icon={false} sx={errorModalBodyStyles}>
          {errorMessage}
        </Alert>
      </Modal>
    );
  }
}

const PreviousStepButton = styled.input`
  color: #616161;
  border-color: #616161;
  background-color: transparent;
`;

const ButtonContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const primaryButtonStyles = {
  width: '73px',
  height: '36px',
  padding: '6px 16px',
  fontSize: '14px',
};

const secondaryButtonStyles = {
  color: '#3A3A3A',
  border: '1px solid var(--secondary-outlinedBorder, rgba(58, 58, 58, 0.50));',
  borderRadius: 1,
  backgroundColor: 'transparent',
  padding: '6px 16px',
  fontSize: '14px',
  width: '73px',
  height: '36px',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: '#3A3A3A',
    boxShadow: 'none',
  },
};

const errorModalStyles = {
  justifyContent: 'center',
  width: '100%',
  borderRadius: '16px',
};

const errorModalTitleStyles = {
  fontSize: '20px',
  fontWeight: 500,
};

const errorModalPrimaryButtonStyles = {
  backgroundColor: '#3A3A3A',
  boxShadow: 'none',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: '#191919',
    borderColor: '#191919',
    boxShadow: 'none',
  },
};

const errorModalSecondaryButtonStyles = {
  color: '#3A3A3A',
  fontSize: '16px',
  marginRight: '16px',
  border: '1px solid var(--secondary-outlinedBorder, rgba(58, 58, 58, 0.50))',
  '&:hover': {
    backgroundColor: 'transparent',
    borderColor: '#3A3A3A',
    boxShadow: 'none',
  },
};

const errorModalBodyStyles = {
  color: nimbleTheme.palette.error.dark,
  width: '100%',
  fontSize: '16px',
  textAlign: 'center',
  justifyContent: 'center',
};
