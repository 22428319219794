import { AxiosResponse } from 'axios';
import { BaseNimbleAPI } from '../../../api/baseAPI.js';
import { formatResumeFileName } from '../features/ExpressInterest/utils';
import { uniqueId } from 'lodash';
import { SchoolConnectResponse } from 'types/connectTypes.js';
interface TalentProfileParams {
  userId: string;
  firstName: string;
  lastName: string;
  phoneCell?: string;
  openToOpportunities: boolean;
  preferenceStates: number[];
}

interface UserPreferencesParams {
  openToConnectOpportunities: boolean;
  userLocation: string;
  distanceFromSchool: string;
  subjects: string[];
  grades: number[];
  schoolsToHideFrom: string[];
}

interface UserCredentialAndProfileParams {
  userId: string;
  credentials: any;
  yearsOfTeachingExperience: string;
  isEligibleToWorkInUS: boolean;
}

class ExpressInterestAPI extends BaseNimbleAPI {
  async getSchoolsUserExpressedInterestIn(): Promise<SchoolConnectResponse[]> {
    const response: AxiosResponse = await this.get('/api/school_connect/');
    return response.data;
  }

  async updateTalentProfile(params: TalentProfileParams) {
    const profileUrl = `/api/user/${params.userId}/`;
    const userPayload = {
      first_name: params.firstName,
      last_name: params.lastName,
      profile: {
        phone_cell: params.phoneCell,
      },
    };

    const preferencesUrl = `/api/preferences/${params.userId}/`;
    const preferencePayload = {
      open_to_opportunities: params.openToOpportunities,
      states: params.preferenceStates,
    };

    const userResponse: AxiosResponse = await this.patch(profileUrl, userPayload);
    if (userResponse.status !== 200) {
      throw new Error('Failed to update user profile');
    }
    const preferenceResponse: AxiosResponse = await this.put(preferencesUrl, preferencePayload);
    if (preferenceResponse.status !== 200) {
      throw new Error('Failed to update user preferences');
    }

    const responseData = {
      profile: userResponse.data,
      preferences: preferenceResponse.data,
    };
    return responseData;
  }

  async updateConnectFirstProfileSetupDateTime(userId: string, currentTime: string) {
    const url = `/api/user/${userId}/`;
    const payload = {
      connect_first_profile_setup: currentTime,
    };
    const response: AxiosResponse = await this.patch(url, payload);
    return response.data;
  }

  async fetchCredentialOptions(stateID: number | string) {
    let credentialOptionsFormatted;

    await this.get(`/api/state/${stateID}/`, {
      params: {
        credential_type: 'connect',
      },
    }).then((response) => {
      credentialOptionsFormatted = response.data.credential_subjects.map((item) => {
        const obj = {};
        obj['label'] = item.value;
        obj['value'] = item.id;
        return obj;
      });
    });
    return {
      credentialOptions: credentialOptionsFormatted,
    };
  }

  async fetchUserProfileAndCredentials(userID: string) {
    let resume;
    let credentials;

    await this.get(`/api/user/${userID}/`).then((response) => {
      resume = formatResumeFileName(response.data.profile.resume);
      credentials = response.data.credentials;

      // client_id added to each credential below is used to uniquely
      // identify each certification input during CRUD operations
      credentials = credentials.map((credential) => {
        const isOtherTypeCredential = !credential.state;
        if (isOtherTypeCredential || credential.subject.show_in_connect == false) {
          return { ...credential, isHidden: true };
        } else {
          return {
            ...credential,
            isHidden: false,
            client_id: uniqueId(),
            errors: { hasNameError: false, hasStatusError: false },
          };
        }
      });
    });

    return { resume, credentials };
  }

  async updateUserCredentialsAndProfile(updatedUserData: UserCredentialAndProfileParams) {
    const userPayload = {
      credentials: updatedUserData.credentials,
      profile: {
        years_of_teaching_experience: updatedUserData.yearsOfTeachingExperience,
        is_eligible_to_work_in_us: updatedUserData.isEligibleToWorkInUS,
      },
    };

    const response: AxiosResponse = await this.patch(
      `/api/user/${updatedUserData.userId}/`,
      userPayload
    );
    return response.data;
  }

  async updateUserPreferences(userId: string, userPreferences: UserPreferencesParams) {
    const preferencesUrl = `/api/preferences/${userId}/`;

    const payload = {
      open_to_connect_opportunities: userPreferences.openToConnectOpportunities,
      miles_within: userPreferences.distanceFromSchool,
      location: userPreferences.userLocation,
      categories_connect: userPreferences.subjects,
      grades: userPreferences.grades,
      hide_user_from_schools_on_connect: userPreferences.schoolsToHideFrom,
    };

    const preferenceResponse: AxiosResponse = await this.put(preferencesUrl, payload);
    if (preferenceResponse.status !== 200) {
      throw new Error('Failed to update user preferences');
    }

    return preferenceResponse.data;
  }

  async sendExpressInterestConnectionRequest(schoolId: string, message?: string) {
    const url = '/api/school_connect/';
    const payload = {
      school_nces_id: schoolId,
      message: message.length == 0 ? null : message,
    };

    const response: AxiosResponse = await this.post(url, payload);
    return { data: response.data, status: response.status };
  }
}

export default new ExpressInterestAPI();
