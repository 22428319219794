import { ATSConnectImportCandidateFormTestIds } from 'data-testids/ATS';
import { Box } from '@mui/material';
import { Key, ModalContainer, Row, SuccessSubContainer, Title } from '../styles';
import { SuccessPageProps } from '../types';

export const SuccessPage: React.FC<SuccessPageProps> = ({
  title,
  candidateName,
  candidateEmail,
  schools,
  internalNote,
}) => {
  const schoolsToReturn: string =
    schools?.length === 0 ? 'District' : schools?.map(({ school }) => school.name).join(', ');

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ModalContainer>
        <Title data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_TITLE}>Success!</Title>

        <SuccessSubContainer>
          <Key data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_TEXT}>
            We&apos;ve successfully imported this candidate from Nimble Connect! Here are the
            details:
          </Key>
        </SuccessSubContainer>

        <SuccessSubContainer>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_CANDIDATE_NAME}>
            <Key>Candidate:</Key> &nbsp;{candidateName}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_CANDIDATE_EMAIL}>
            <Key>Candidate email:</Key> &nbsp; {candidateEmail}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_ROLE_TITLE}>
            <Key>Role:</Key> &nbsp;{title}
          </Row>
          <Row data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_ROLE_LOCATION}>
            <Key>Location:</Key> &nbsp;{schoolsToReturn}
          </Row>
        </SuccessSubContainer>

        {internalNote && (
          <SuccessSubContainer
            data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_CANDIDATE_INTERNAL_NOTE}
          >
            <Key>Notes: &nbsp;</Key>
            {internalNote}
          </SuccessSubContainer>
        )}

        <SuccessSubContainer data-testid={ATSConnectImportCandidateFormTestIds.SUCCESS_NEXT_STEPS}>
          <Key>Here&apos;s what&apos;s next: &nbsp;</Key>
          <li>We&apos;ve invited this candidate to apply to this role.</li>
          <li>
            Once they have submitted their application, it will be reviewed by your central HR team.
          </li>
          <li>
            After your central HR team&apos;s approval, the candidate&apos;s application will become
            visible to you in Nimble.
          </li>
        </SuccessSubContainer>
      </ModalContainer>
    </Box>
  );
};
