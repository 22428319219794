import { useEffect, useState } from 'react';

import { ApplicationStatusChip } from 'sharedComponents/Chip/StatusChip';
import { JobViewDetailProps, JobViewDetailsProps, ReadMoreListProps } from '../types';
import { TextButton } from 'sharedComponents/Buttons';
import { Box } from '@mui/material';
import {
  DetailContainer,
  DetailsContainer,
  JobViewSectionTitle,
  JobDetailSubtitle,
  ListBox,
  StyledLink,
  Value,
} from './styles';
import {
  getGradesForJobView,
  getJobboardURL,
  getSalaryRange,
  getSchoolsForJobView,
  getSubcategories,
  getSubjectsForJobView,
  initialNumberOfStatusesToShow,
  shouldShowJobViewField,
  truncateText,
  truncationCharacterLimit,
} from '../utils';
import { nimbleTheme } from 'theme';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import moment from 'moment';

const JobViewDetail: React.FC<JobViewDetailProps> = ({ children, subtitle, dataTestId }) => {
  return (
    <DetailContainer data-testid={dataTestId}>
      <JobDetailSubtitle variant="h3" data-testid={`${dataTestId}-key`}>
        {subtitle}
      </JobDetailSubtitle>
      <Value
        variant="subtitle"
        data-testid={`${dataTestId}-value`}
        sx={{
          fontSize: nimbleTheme.typography.body1,
          fontWeight: nimbleTheme.typography.fontWeightBold,
        }}
      >
        {children}
      </Value>
    </DetailContainer>
  );
};

export const ReadMoreList: React.FC<ReadMoreListProps> = ({ text, onClick, isMore, field }) => {
  const hasMoreTextToRead = text.length > truncationCharacterLimit;

  return (
    <>
      <Box data-testid={`${field}-read-more-text`}>{text}</Box>
      {hasMoreTextToRead && (
        <TextButton
          dataTestId={`${field}-read-more-button`}
          onClick={onClick}
          sx={{
            color: nimbleTheme.palette.success.main,
            fontWeight: nimbleTheme.typography.fontWeightBold,
            margin: 0,
            padding: 0,
          }}
        >
          {isMore ? 'View less' : 'View more'}
        </TextButton>
      )}
    </>
  );
};

export const JobViewDetails: React.FC<JobViewDetailsProps> = ({ job }) => {
  const [readMore, setReadMore] = useState({
    schools: false,
    grades: false,
    subjects: false,
    statuses: false,
  });
  const [subcategories, setSubcategories] = useState([]);

  useEffect(() => {
    getSubcategories(job.district.id).then((res) => {
      setSubcategories(res);
    });
  }, [job.district.id]);

  const deadlineText = job.deadline
    ? `${moment(job.deadline).format('ll')} at 8PM PST`
    : 'Until filled';

  const startDate = job.start_date ? moment(job.start_date).format('MMMM YYYY') : null;
  const schools = job.schoolroles ? getSchoolsForJobView(job) : '';
  const grades = getGradesForJobView(job);
  const subjects = getSubjectsForJobView(job, subcategories);
  const statusesToRender = readMore.statuses
    ? job.statuses_available
    : job.statuses_available?.slice(0, initialNumberOfStatusesToShow - 1);
  const shouldShowReadMoreStatusesButton =
    job.statuses_available?.length > initialNumberOfStatusesToShow;

  return (
    <DetailsContainer data-testid="jobview-details" component="section">
      <JobViewSectionTitle variant="h2" sx={{ paddingBottom: nimbleTheme.spacing(2.5) }}>
        Job Details
      </JobViewSectionTitle>
      {shouldShowJobViewField(job, 'schoolroles') && (
        <JobViewDetail subtitle="Schools" dataTestId="schools">
          <ReadMoreList
            field="schools"
            text={truncateText(schools, readMore['schools'])}
            isMore={readMore.schools}
            onClick={() =>
              setReadMore((previousState) => ({
                ...previousState,
                schools: !readMore.schools,
              }))
            }
          />
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'deadline') && (
        <JobViewDetail subtitle="Application Deadline" dataTestId="deadline">
          {deadlineText}
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'start_date') && (
        <JobViewDetail subtitle="Start Date" dataTestId="start-date">
          {startDate}
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'salary_max') && (
        <JobViewDetail subtitle="Salary" dataTestId="salary">
          {getSalaryRange(job)}
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'grades') && (
        <JobViewDetail subtitle="Grades" dataTestId="grades">
          <ReadMoreList
            field="grades"
            text={truncateText(grades, readMore['grades'])}
            isMore={readMore.grades}
            onClick={() =>
              setReadMore((previousState) => ({
                ...previousState,
                grades: !readMore.grades,
              }))
            }
          />
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'subjects') && (
        <JobViewDetail subtitle="Subjects" dataTestId="subjects">
          <ReadMoreList
            field="subjects"
            text={truncateText(subjects, readMore['subjects'])}
            isMore={readMore.subjects}
            onClick={() =>
              setReadMore((previousState) => ({
                ...previousState,
                subjects: !readMore.subjects,
              }))
            }
          />
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'scorecards') && (
        <JobViewDetail subtitle="Scorecards" dataTestId="scorecards">
          {job.scorecards.map((scorecard) => scorecard.title).join('; ')}
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'statuses_available') && (
        <JobViewDetail subtitle="Status" dataTestId="status">
          <ListBox component="ul">
            {statusesToRender.map((status) => (
              <ApplicationStatusChip
                dataTestId={`chip-${status.id}`}
                key={status.id}
                color={status.color}
                label={status.label}
              />
            ))}
          </ListBox>
          {shouldShowReadMoreStatusesButton && (
            <TextButton
              dataTestId={`statuses-read-more-button`}
              onClick={() =>
                setReadMore((previousState) => ({
                  ...previousState,
                  statuses: !readMore.statuses,
                }))
              }
              sx={{
                color: nimbleTheme.palette.success.main,
                padding: 0,
                margin: 0,
                fontWeight: nimbleTheme.typography.fontWeightBold,
              }}
            >
              {readMore.statuses ? 'View less' : 'View more'}
            </TextButton>
          )}
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'contact') && (
        <JobViewDetail subtitle="Contact for Questions" dataTestId="contact">
          <StyledLink href={`mailto:${job.contact}`}>
            Send message &#32;
            <ArrowOutwardIcon fontSize="small" />
          </StyledLink>
        </JobViewDetail>
      )}

      {shouldShowJobViewField(job, 'jobboards') && (
        <JobViewDetail subtitle="District Job Board" dataTestId="contact">
          <StyledLink target="_blank" href={getJobboardURL(job)}>
            View district board &#32;
            <ArrowOutwardIcon fontSize="small" />
          </StyledLink>
        </JobViewDetail>
      )}
    </DetailsContainer>
  );
};
