import { activeStatusByValue } from 'utils/enums';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  BackToJobsLink,
  ChipContainer,
  ChipDistrictToggleContainer,
  HeaderContainer,
  InlineBlock,
  JobTitle,
  Left,
  Right,
} from './styles';
import { getEmploymentTypeText, shouldShowBackToJobsLink, shouldShowStatusToggle } from '../utils';
import { JobStatusChip } from 'sharedComponents/Chip/StatusChip';
import { JobStatus, JobViewHeaderProps } from '../types';
import { JobViewTitleButtons } from './JobViewTitleButtons';
import { TextButton } from 'sharedComponents/Buttons';
import { Box, Typography, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import auth from 'utils/auth';
import CancelIcon from '@mui/icons-material/Cancel';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { ATSJobViewDataTestIds } from 'data-testids/ATS';

export const JobViewHeader: React.FC<JobViewHeaderProps> = ({
  handleOpenShareModal,
  isInternalCandidate,
  isPreview,
  job,
  onSubmit,
  toggleJobStatus,
  userDistrict,
}) => {
  const { title, fulltime, district } = job || {};
  const shouldShowJobStatusChip = auth.isDistrictUser() || auth.isSchoolAdmin();
  const location = useLocation();
  const isAtSchoolRoute = location.pathname.includes('/school/jobview');
  const isAtDistrictRoute = location.pathname.includes('/district/jobview');
  const isAtValidRoute = isAtSchoolRoute || isAtDistrictRoute;
  const backToJobsLink = auth.isSchoolAdmin() ? '/school/jobslist' : '/district/jobslist';
  const isActiveOpen = job.active_status === activeStatusByValue.activeOpen;
  const theme = useTheme();
  const showStatusToggle = shouldShowStatusToggle(job?.status, location.pathname);

  return (
    <HeaderContainer data-testid="jobview-v2-header" component="hgroup">
      {shouldShowBackToJobsLink(isAtValidRoute) && (
        <BackToJobsLink href={backToJobsLink}>
          <ArrowBackIcon />
          Back to all jobs
        </BackToJobsLink>
      )}
      <Left>
        <JobTitle data-testid={ATSJobViewDataTestIds.TITLE_TEXT} variant="h1">
          {title}
        </JobTitle>
      </Left>
      <Right>
        <JobViewTitleButtons
          handleOpenShareModal={handleOpenShareModal}
          isInternalCandidate={isInternalCandidate}
          isPreview={isPreview}
          job={job}
          onSubmit={onSubmit}
          userDistrict={userDistrict}
        />
      </Right>
      <ChipDistrictToggleContainer
        data-testid="fte-and-district-name"
        showStatusToggle={showStatusToggle}
      >
        <Box>
          {shouldShowJobStatusChip && (
            <ChipContainer>
              <JobStatusChip
                activeStatus={job?.active_status}
                dataTestId="job-status-chip"
                status={job?.status as JobStatus}
                sx={{ display: 'inline', padding: theme.spacing(0.25) }}
              />
            </ChipContainer>
          )}

          <InlineBlock sx={{ marginRight: theme.spacing(1.5) }}>
            <Typography sx={{ display: 'inline' }}>
              {getEmploymentTypeText(fulltime)} • {district?.name}
            </Typography>
          </InlineBlock>
        </Box>

        {showStatusToggle && (
          <InlineBlock>
            <TextButton
              dataTestId="toggle-button"
              onClick={toggleJobStatus}
              sx={{
                fontSize: 'inherit',
                color: 'inherit',
                '& .MuiButton-startIcon': { marginRight: theme.spacing(0.5) },
              }}
              startIcon={
                isActiveOpen ? (
                  <CancelIcon
                    sx={{
                      height: theme.spacing(2.25),
                      width: theme.spacing(2.25),
                    }}
                  />
                ) : (
                  <OpenInFullIcon
                    sx={{ height: theme.spacing(2.25), width: theme.spacing(2.25) }}
                  />
                )
              }
            >
              {isActiveOpen ? 'Close this job' : 'Open this job'}
            </TextButton>
          </InlineBlock>
        )}
      </ChipDistrictToggleContainer>
    </HeaderContainer>
  );
};
