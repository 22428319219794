import { Autocomplete, Box, TextField } from '@mui/material';

import { OptionTitle } from '../styles';
import { JobComboBoxProps } from '../types';

export const JobComboBox: React.FC<JobComboBoxProps> = ({
  onInputChange,
  options,
  isLoading,
  onChange,
  inputValue,
}) => {
  const handleRoleIdSelection = (value) => {
    onChange(value?.id);
  };

  return (
    <Autocomplete
      freeSolo={inputValue?.length ? false : true}
      onChange={(_event, newValue) => {
        handleRoleIdSelection(newValue);
      }}
      onInputChange={(_e, value) => {
        onInputChange(value);
      }}
      options={options ? options : []}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
      loading={inputValue?.length > 0 ? isLoading : false}
      renderInput={(params) => (
        <TextField
          placeholder="Type or select job"
          {...params}
          InputLabelProps={{
            shrink: false,
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box {...props} component="li" key={option.id}>
          <OptionTitle>{option.title}</OptionTitle>
        </Box>
      )}
    />
  );
};
