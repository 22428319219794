import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { JobDiscoveryFilterContainerProps } from 'types/connectTypes';
import { JobDiscoveryLocationFilter } from './JobDiscoveryLocationFilter';
import { Filters } from './JobDiscoveryFilters';
import { Header } from '../JobDiscoveryHeader';
import { ConnectFilterName } from 'features/Connect/utils/connectEnums';
import { getDefaultMap } from '../utils';
import { SearchbarMultiSelect } from 'sharedComponents/Select/SearchbarMultiSelect';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useStateParam } from '../../ConnectStateCodeContextProvider';

export const JobDiscoveryFilterContainer: React.FC<JobDiscoveryFilterContainerProps> = ({
  filterValues,
  actions,
  filterOptions,
  isUserAuthenticated,
  isLoading,
  activeFilters,
}) => {
  const jobboardState = useStateParam();
  const [selectedSubjectOptions, setSelectedSubjectOptions] = useState([]);
  const [selectedGradeOptions, setSelectedGradeOptions] = useState([]);
  const [selectedSchoolOptions, setSelectedSchoolOptions] = useState([]);
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [mapUrl, setMapUrl] = useState<string>();

  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'lg'));

  const getSelectedOptions = (selectedOptions) => {
    if (!Array.isArray(selectedOptions)) {
      return [];
    }
    return selectedOptions.map((option) => option.value);
  };

  const updateSelectedFilterOptions = (
    selectedValues,
    filterOptions,
    updateSelectedValues,
    setFilterIsActive,
    filtername
  ) => {
    actions.setIsLoadingInitialFilterResults(true);
    const selectedOptions = getSelectedOptions(selectedValues);

    if (selectedValues?.length === filterOptions?.length || selectedValues?.length === 0) {
      updateSelectedValues([]);
      setFilterIsActive(filtername, false);
    } else {
      updateSelectedValues(selectedOptions);
      setFilterIsActive(filtername, true);
    }
  };

  useEffect(() => {
    updateSelectedFilterOptions(
      selectedSubjectOptions,
      filterOptions.selectedSubjectIds,
      actions.setSelectedSubjectIds,
      actions.setFilterIsActive,
      ConnectFilterName.SUBCATEGORIES
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubjectOptions]);

  useEffect(() => {
    updateSelectedFilterOptions(
      selectedGradeOptions,
      filterOptions.selectedGrades,
      actions.setSelectedGrades,
      actions.setFilterIsActive,
      ConnectFilterName.GRADES
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGradeOptions]);

  useEffect(() => {
    updateSelectedFilterOptions(
      selectedSchoolOptions,
      filterOptions.selectedSchools,
      actions.setSelectedSchoolIds,
      actions.setFilterIsActive,
      ConnectFilterName.SCHOOLS
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchoolOptions]);

  useEffect(() => {
    updateSelectedFilterOptions(
      selectedDistrictOptions,
      filterOptions.selectedDistricts,
      actions.setSelectedDistrictIds,
      actions.setFilterIsActive,
      ConnectFilterName.DISTRICTS
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistrictOptions]);

  useEffect(() => {
    setShowMap(mapUrl?.length > 0);
  }, [mapUrl]);

  return (
    <FilterContainer>
      <Header isUserAuthenticated={isUserAuthenticated} />

      <MapContainer sx={{ display: { md: 'none' } }}>
        {showMap ? (
          <Map src={mapUrl} alt="locations" />
        ) : (
          <Map src={getDefaultMap(jobboardState)} alt="default-location" />
        )}
      </MapContainer>
      <Grid container spacing={1.5} justifyContent={'space-between'}>
        <Grid
          item
          sx={{ display: { xs: 'block', md: 'none', lg: 'block' } }}
          xs={12}
          sm={12}
          lg={5}
        >
          <SearchbarMultiSelect
            displayName="School search"
            size="small"
            placeholder="Search for school"
            options={filterOptions.schoolOptions}
            values={selectedSchoolOptions}
            handleChange={setSelectedSchoolOptions}
            dataTestId={ConnectJobDiscoveryFiltersDataTestIds.SCHOOL_SEARCH_FILTER}
            sx={InputStyles(theme)}
            hasSubLabel={true}
            subLabelKeys={['address.city']}
          />
        </Grid>
        <Grid item sx={{ display: { xs: 'block', md: 'none' } }} xs={12}>
          <Filters
            filterValues={filterValues}
            filterOptions={filterOptions}
            isMobile={isMobile}
            setSelectedGradeOptions={setSelectedGradeOptions}
            setSelectedSubjectOptions={setSelectedSubjectOptions}
            setSelectedDistrictOptions={setSelectedDistrictOptions}
            activeFilters={activeFilters}
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <JobDiscoveryLocationFilter
            distanceDisplayName="Distance"
            locationDisplayName="Enter address or zip code"
            filterValues={filterValues}
            locationChangeHandler={actions.setSelectedLocation}
            filterOptions={filterOptions.distanceOptions}
            actions={actions}
            setMapUrl={setMapUrl}
            setShowMap={setShowMap}
            mapUrl={mapUrl}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <MapContainer sx={{ display: { xs: 'none', md: 'flex' } }}>
        {showMap ? (
          <Map src={mapUrl} alt="locations" />
        ) : (
          <Map src={getDefaultMap(jobboardState)} alt="default-mobile-locations" />
        )}
      </MapContainer>
      <Grid container spacing={1.5} justifyContent={'space-between'}>
        <Grid item sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }} xs={12} sm={12} lg={5}>
          <SearchbarMultiSelect
            displayName="School search"
            size="small"
            placeholder="Search for school"
            options={filterOptions.schoolOptions}
            values={selectedSchoolOptions}
            handleChange={setSelectedSchoolOptions}
            dataTestId={ConnectJobDiscoveryFiltersDataTestIds.SCHOOL_SEARCH_FILTER}
            sx={InputStyles(theme)}
          />
        </Grid>
        <Grid
          item
          sx={{ display: { xs: 'none', md: 'block' } }}
          lg={9}
          md={12}
          padding={`${theme.spacing(0.5)} 0`}
        >
          <Filters
            filterValues={filterValues}
            filterOptions={filterOptions}
            isMobile={isMobile}
            setSelectedGradeOptions={setSelectedGradeOptions}
            setSelectedSubjectOptions={setSelectedSubjectOptions}
            setSelectedDistrictOptions={setSelectedDistrictOptions}
            activeFilters={activeFilters}
          />
        </Grid>
      </Grid>
    </FilterContainer>
  );
};

const FilterContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: `0px 0px ${theme.spacing(3)}`,
  },
}));

const MapContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  border: `solid 1px ${theme.palette.gray.darker}`,
  borderRadius: theme.shape.borderRadius * 3,
  margin: `${theme.spacing(2)} 0px`,
  [theme.breakpoints.down('md')]: {
    height: '148px',
    margin: `${theme.spacing(3)} 0px`,
  },
}));

const Map = styled('img')(({ theme }) => ({
  background: 'url(${props => props.src}) lightgray 50% / cover no-repeat',
  alignSelf: 'center',
  backgroundPosition: 'center',
  width: '100%',
  height: '224px',
  borderRadius: theme.shape.borderRadius * 3,
  border: `solid 1px ${theme.palette.gray.darker}`,
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

export const InputStyles = (theme: Theme) => ({
  width: '100%',
  fontSize: theme.typography.subtitle.fontSize,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
  },
  '& .MuiInputLabel-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle.fontSize,
    },
  },
});
